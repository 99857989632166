import React, { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { FileUploader } from "react-drag-drop-files";
import Button from "@mui/material/Button";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import Spinner from "../../../components/spinner";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import useAddCalorie from "../../../hooks/calorie/useAddCalorie";
import useUpload from "../../../hooks/useUpload";
import { TextField } from "@mui/material";
import useEditCalorie from "../../../hooks/calorie/useEditCalorie";
import Editor from "../../../components/elements/Editor";
import useMoodListing from "../../../hooks/music/useMoodListing";
import useAddExercise from "../../../hooks/exercise/useAddExercise";
import useMuscleListing from "../../../hooks/exercise/useMuscleListing";
import useEditExercise from "../../../hooks/exercise/useEditExercise";

const fileTypes = ["JPG", "PNG", "JPEG"];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddExercise() {
  const { exerciseId } = useParams();
  const navigate = useNavigate();
  const productImageUpload = useRef(null);
  const queryClient = useQueryClient();
  const initialState = {
    exerciseId: "",
    title: "",
    description: "",
    content: "",
    avatarId: [],
    workoutType: "",
    muscleGroup: [],
    avatarPreviews: [],
  };

  // const { fetchSingleMusic, updateMusic, handleEditForm } = useEditMusic();
  const { fetchSingleExercise, updateExercise, handleEditForm } =
    useEditExercise();
  const [productData, setProductData] = useState(initialState);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { handleUpload: uploadAvatar } = useUpload({
    refreshUrl: "music/audio_listing",
    url: "upload/upload_media",
    onSuccess: (response) => {
      const { media_id, media_url } = response.data;
      if (media_id && media_url) {
        setProductData((prev) => ({
          ...prev,
          avatarId: media_id,
          avatarPreview: media_url,
        }));
      } else {
        console.error("Invalid response data:", response);
      }
    },
    onError: (error) => {
      console.error("Upload Error:", error);
      setIsError(true);
    },
  });
  const handleAvatarUpload = (files) => {
    const uploadPromises = Array.from(files).map((file) =>
      uploadAvatar(file, "audio/avatar")
    );

    Promise.all(uploadPromises)
      .then((responses) => {
        const updatedAvatarIds = responses?.map(
          (response) => response.data.media_id
        );
        const updatedAvatarPreviews = responses?.map(
          (response) => response.data.media_url
        );

        setProductData((prev) => ({
          ...prev,
          avatarIds: [...prev.avatarId, ...updatedAvatarIds],
          avatarPreviews: [...prev.avatarPreviews, ...updatedAvatarPreviews],
        }));
      })
      .catch((error) => {
        console.error("Upload Error:", error);
        setIsError(true);
      });
  };

  const handleCloseAndReset = () => {
    setProductData({
      exerciseId: "",
      title: "",
      content: "",
      avatarId: "",
      description: "",
      avatarPreview: null,
    });
    setIsError(false);
    setIsSuccess(false);
  };

  const handleAddFromData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setProductData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const { handleAddExercise } = useAddExercise();

  useEffect(() => {
    if (exerciseId) {
      fetchSingleExercise(exerciseId)
        .then((data) => {
          setProductData({
            exerciseId,
            title: data?.data?.title || "",
            content: data?.data?.content || "",
            avatarId: data?.data?.avatarId || "",
            workoutType: data?.data?.workoutType || "",
            muscleGroup:
              data?.data?.muscleGroup.map((item) => item.muscleId) || [],
            description: data?.data?.description || "",
            avatarPreview: data?.data?.avatarUrl || null,
          });
        })
        .catch(() => setIsError(true));
    }
  }, [exerciseId, fetchSingleExercise]);
  const { queryData } = useMuscleListing();

  const musclelist = queryData?.data?.data;
  const handleMuscleSelection = (muscleId, isChecked) => {
    setProductData((prev) => {
      let updatedMuscleGroup;
      if (isChecked) {
        // Add selected moodId as an object
        updatedMuscleGroup = [...prev.muscleGroup, { muscleId }];
      } else {
        // Remove the unselected moodId
        updatedMuscleGroup = prev.muscleGroup.filter(
          (item) => item.muscleId !== muscleId
        );
      }
      return {
        ...prev,
        muscleGroup: updatedMuscleGroup,
      };
    });
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    const formData = {
      ...productData,
      avatarIds: productData.avatarId, // Ensure all avatar IDs are included
    };

    if (exerciseId) {
      handleEditForm(formData);
    } else {
      handleAddExercise(e, formData);
    }
  };

  const handleChangeEditor = (e, value) => {
    let data = {
      ...productData,
      description: value,
    };
    setProductData({ ...data });
  };

  return (
    <>
      <div className="container_xxl bg-white">
        <div className="flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white">
          <div>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div>
            <h1 className="heading">Add Exercise</h1>
          </div>
        </div>
        <div className="form-body py-8">
          <form className="" autoComplete="off" onSubmit={handleSubmitForm}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="Title" className="label">
                  Title <small className="text-red-700">*</small>
                </label>
                <TextField
                  required
                  type="text"
                  placeholder="Title"
                  name="title"
                  className="input"
                  value={productData.title}
                  onChange={handleAddFromData}
                />
              </div>
              <div>
                <label className="label">Muscle Group</label>
                <div className="space-x-4 pt-2">
                  {musclelist?.map((muscle, idx) => {
                    return (
                      <label
                        key={muscle.muscleId} // Use muscleId as key for better uniqueness
                        className="border border-gray-300 rounded p-2 space-x-2 cursor-pointer select-none"
                      >
                        <input
                          type="checkbox"
                          name="muscleGroup"
                          data-id={muscle.muscleId}
                          // value={muscle?.muscleId}
                          checked={productData?.muscleGroup.includes(
                            muscle.muscleId
                          )}
                          onChange={(e) =>
                            handleMuscleSelection(
                              muscle.muscleId,
                              e.target.checked
                            )
                          }
                        />
                        <span className="capitalize">{muscle.name}</span>
                      </label>
                    );
                  })}
                </div>
              </div>
              <div>
                <label htmlFor="Content" className="label">
                  Content<small className="text-red-700">*</small>
                </label>
                <TextField
                  // required
                  type="text"
                  placeholder="Content"
                  name="content"
                  className="input"
                  value={productData.content}
                  onChange={handleAddFromData}
                />
              </div>
              <div>
                <label htmlFor="workoutType" className="label">
                  Workout Type<small className="text-red-700">*</small>
                </label>
                <select
                  name="workoutType"
                  // required
                  value={productData.workoutType}
                  onChange={handleAddFromData}
                  className="input"
                >
                  <option value="">--Select Workout Type--</option>
                  <option value="without equipment">Without Equipment</option>
                  <option value="with equipment">With Equipment</option>
                </select>
              </div>

              <div>
                <label htmlFor="Desciption" className="label">
                  Description<small className="text-red-700">*</small>
                </label>
                <Editor
                  value={productData?.description}
                  onChange={handleChangeEditor}
                />
              </div>

              <div>
                <div className="my-7 flex items-center justify-between border-b border-gray-400">
                  <div className="pb-3">
                    <h1 className="font-semibold text-2xl">Avatar</h1>
                    <small className="text-red-700">
                      Note: Image to be less than 1mb
                    </small>
                  </div>
                </div>
                <div className=" space-y-4">
                {!productData.avatarPreviews ? (
                  <FileUploader
                    name="avatarPreview"
                    types={fileTypes}
                    multiple={true} // Enable multiple file uploads
                    handleChange={handleAvatarUpload}
                    classes="file-uploader"
                    hoverTitle="Drop here"
                  />
                ) : (
                  <div className="border border-dashed border-blue-700 rounded">
                    <div className="w-40 mx-auto py-4 text-center space-y-3">
                      <img src={productData.avatarPreviews} alt="preview" />
                      <Button
                        variant="outlined"
                        onClick={() =>
                          setProductData((prev) => ({
                            ...prev,
                            avatarId: "",
                            avatarPreview: null,
                          }))
                        }
                      >
                        Re-Upload
                      </Button>
                    </div>
                  </div>
                )}
                  {productData?.avatarPreviews?.map((avatar, index) => (
                    <div
                      key={index}
                      className="border border-dashed border-blue-700 rounded my-2"
                    >
                      <div className="w-40 mx-auto py-4 text-center space-y-3">
                        <img src={avatar} alt={`preview-${index}`} />
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setProductData((prev) => ({
                              ...prev,
                              avatarIds: prev.avatarIds.filter(
                                (_, idx) => idx !== index
                              ),
                              avatarPreviews: prev.avatarPreviews.filter(
                                (_, idx) => idx !== index
                              ),
                            }));
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="text-center my-6">
              {handleAddExercise.isLoading ? (
                <Spinner />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="primaryBtn-contained"
                >
                  Save
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddExercise;
