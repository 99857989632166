import './footer.css'

function Footer() {
  return (
    <div className='bg-black text-white text-center py-2'>
        <small>Apex Vitals Dashboard</small>
    </div>
  )
}

export default Footer