import React from "react";
import useCreate from "../useCreate";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const useAddExercise = () => {
  const navigate = useNavigate();
  // Hook for creating a mood
  const { setDataToServer: createExercise } = useCreate({
    refreshUrl: "exercise/exercise_listing",
    url: "exercise/create_exercise",
    onSuccess: () => {
        navigate(-1)
       
    },
    onError: () => {},
  });

  const handleAddExercise = (e, data) => {
    e.preventDefault();
    console.log(data);
    createExercise.mutate(data);
  };

  return { handleAddExercise, createExercise };
};

export default useAddExercise;
