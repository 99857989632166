import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Spinner from "../../../components/spinner";
import { Alert, Snackbar } from "@mui/material";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

import Layout from "../../../components/layout";
import useRead from "../../../hooks/useRead";
import DeleteCalorie from "../DeleteCalorie";
import Errors from "../../errors";
import DeleteExercise from "./DeleteExercise";

function ExerciseListing() {
  const initialData = {
    searchQuery: "",
    pageNo: 0,
    limit: 10,
  };
  const { queryData, paramsObject, setGetListParams } = useRead({
    url: "exercise/exercise_listing",
    initialData,
  });
  const [foodItemId, setFoodItemId] = useState(0);
  const [exerciseId, setExerciseId] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const handleSearchVal = (e) => {
    let value = e.target.value;
    let data = {
      ...paramsObject,
      pageNo: 0,
      [e.target.name]: value,
    };
    setGetListParams(data);
  };
  const handleOpenAddCoupon = () => {
    setOpenAddModal(true);
  };
  const handleModalClose = () => {
    setOpenAddModal(false);
    setIsOpenEditModal(false);
    setOpenDeleteModal(false);
  };
  const [singleBlogData, setSingleBlogData] = useState("");
  const handleOpenEditModal = (id) => {
    setIsOpenEditModal(true);
    let data = queryData.data.data.find((x) => x.id === id);
    setSingleBlogData(data);
  };
  const [categoryId, setCategoryId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (exerciseId) => {
    setOpenDeleteModal(true);
    setExerciseId(exerciseId);
  };
  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false);
  const deletedSuccess = () => {
    handleModalClose();
    setIsDeletedSuccess(true);
  };
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      pageNo: val.selected,
    };
    setGetListParams(data);
  };
  return (
    <>
      <Layout>
        <div className="blogs">
          <div className="blog__head headingBorder__b">
            <h2 className="titleHeading">Exercise</h2>
          </div>
          <div className="blog_table py-5 space-y-5">
            <div className="flex items-center space-x-4">
              <div className="flex-1">
                <input
                  onChange={handleSearchVal}
                  type="text"
                  name="searchQuery"
                  placeholder="Search Exercise...."
                  className="input"
                />
              </div>
              <div>
                <Link to={"muscle"}>
                  <Button variant="outlined" className="">
                    Muscle
                  </Button>
                </Link>
              </div>
              <Link to={"add-exercise"}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className="primaryBtn-outlined"
                >
                  Add Exercise
                </Button>
              </Link>
            </div>
            {queryData?.isLoading ? (
              <Spinner />
            ) : queryData?.status === "error" ? (
              <Errors errorObject={queryData.error} inline />
            ) : (
              <>
                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                  {queryData?.data?.data?.length > 0 ? (
                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                      <thead>
                        <tr className="text-left">
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                            Sr.no
                          </th>
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Exercise
                          </th>
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Workout Type
                          </th>
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Status
                          </th>
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {queryData?.data?.data?.map((item, idx) => {
                          const {
                            title,
                            workoutType,
                            exerciseId,
                            muscleGroup,
                            status,
                            id,
                          } = item;
                          return (
                            <tr key={exerciseId}>
                              <td className="border-dashed border-t border-gray-200 userId">
                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                  {idx + 1}
                                </span>
                              </td>
                              <td className="border-dashed border-t border-gray-200 firstName">
                                <span className="text-gray-700 px-6 py-3 text-center block text-lg">
                                  {title}
                                </span>
                              </td>
                              <td className="border-dashed border-t border-gray-200 firstName">
                                <span className="text-gray-700 px-6 py-3 text-center block text-lg">
                                  {workoutType}
                                </span>
                              </td>

                              <td className="border-dashed border-t border-gray-200 emailAddress">
                                <div className="flex items-center justify-center">
                                  {status ? (
                                    <span className="px-4 py-1 font-semibold rounded bg-green-700 text-green-200">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="px-4 py-1 font-semibold rounded bg-red-700 text-red-200">
                                      Disabled
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td className="border-dashed border-t border-gray-200 phoneNumber">
                                <div className="flex items-center space-x-5 justify-center">
                                  <div>
                                    {/* <IconButton
                                    onClick={() 
                                  > */}
                                    <Link to={`edit-exercise/${exerciseId}`}>
                                      <EditIcon />
                                    </Link>
                                    {/* </IconButton> */}
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleOpenDeleteModal(exerciseId)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="p-5 text-center text-gray-500">
                      No data found
                    </div>
                  )}
                </div>
                <div className=" my-7">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    initialPage={queryData.data?.page_no}
                    pageCount={Math.ceil(queryData.data?.total_count / 10)}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="flex items-center justify-center space-x-4"
                    pageLinkClassName="pageNumber"
                    previousLinkClassName="pageNumber"
                    nextLinkClassName="pageNumber"
                    activeLinkClassName="selectedPageNumber"
                    disabledClassName="lastPage"
                    disabledLinkClassName="lastPage"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>

      <DeleteExercise
        url={"exercise/delete_exercise"}
        queryKey={"exercise/exercise_listing"}
        open={openDeleteModal}
        onClose={handleModalClose}
        exerciseId={exerciseId}
        onDelete={deletedSuccess}
      />
      <Snackbar
        open={isDeletedSuccess}
        autoHideDuration={6000}
        onClose={() => setIsDeletedSuccess(false)}
      >
        <Alert
          onClose={() => setIsDeletedSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Exercise Deleted Succesfully
        </Alert>
      </Snackbar>
    </>
  );
}

export default ExerciseListing;
