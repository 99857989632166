import useRead from "../../hooks/useRead"

const UpdateProductFlavour = ({ productData, setProductData }) => {
    const { queryData } = useRead({ url: 'flavourlisting' })
    const handleAddFlavour = () => {
        const tempArr = productData.flavour
        tempArr.push({
            flavour_name: '',
            stock: ''
        })
        setProductData({
            ...productData,
            flavour: tempArr
        })
    }
    const handleProductFlavourChange = (e, idx) => {
        let value = e.target.value
        let data = queryData.data.data.find(x => x.flavour_name === value)
        let name = e.target.name
        const tempArr = productData.flavour
        tempArr[idx][name] = value
        if (data) {
            tempArr[idx] = { ...data }
        }
        setProductData({
            ...productData,
            flavour: tempArr
        })
    }
    const handleRemoveFlavour = (idx) => {
        const tempArr = productData.flavour
        tempArr.splice(idx, 1)
        setProductData({
            ...productData,
            flavour: tempArr
        })

    }
    return (
        <div className='my-4'>
            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                <h1 className='font-semibold text-2xl pb-3'>Product Flavours</h1>
                <div>
                    <button onClick={handleAddFlavour} type='button' className='border-gray-800 bg-gray-800 text-white px-6 py-2 rounded mb-2'>
                        <span>Add Flavour</span>
                    </button>
                </div>
            </div>
            <div>
                <div className="grid grid-cols-4 gap-4">
                    {productData?.flavour?.map((item, idx) => {
                        return <div key={idx} className=" space-y-3 border rounded border-gray-400 p-4">
                            <div>
                                <label className='label'>Flavour Stock</label>
                                <select name="flavour_name" value={item.flavour_name} className="input" onChange={(e) => handleProductFlavourChange(e, idx)}>
                                    <option>--Select Flavour--</option>
                                    {queryData.data?.data?.map((flavourData, idx) => {
                                        const { flavour_name } = flavourData
                                        return <option key={idx} value={flavour_name}>{flavour_name}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <label className='label'>Flavour Stock</label>
                                <input type="text" className="input" name="stock" value={item.stock} onChange={(e) => handleProductFlavourChange(e, idx)} />
                            </div>
                            <button onClick={() => handleRemoveFlavour(idx)} type='button' className='bg-red-600 text-white px-6 py-2 rounded mb-2 w-full'>
                                <span>Remove Flavour</span>
                            </button>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default UpdateProductFlavour