import { Button, Dialog, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useDeleteDataFromServer } from '../../hooks/useDelete';
import Spinner from '../../components/spinner';

function DeleteBlogs({ open, onClose, id, onDelete, url, queryKey }) {
    const { setDeleteData } = useDeleteDataFromServer(url, queryKey, {
        onSuccesCB: () => {
            onClose()
            onDelete()
        },
        onErrorCD: (err) => {
            console.log(err);
        }
    })

    const handleDeleteCategory = (e) => {
        e.preventDefault()
        let data = {
            id
        }
        setDeleteData.mutate(data)

    }
    return (
        <>
            <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={onClose}>
                <div className=' bg-white w-full px-5'>
                    <div className='modal-head flex items-center justify-end'>
                        <div onClick={onClose}>
                            <IconButton>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <form className=' space-y-4' onSubmit={handleDeleteCategory}>
                        <div className=' space-y-7'>
                            <div>
                                <h1 className=' text-2xl text-red-700 font-semibold text-center'>Are You Sure You Want To Delete?</h1>
                            </div>
                        </div>
                        <div className=' space-x-4 text-center pb-5'>
                            {setDeleteData.isLoading
                                ? <Button type='button'>
                                    <Spinner />
                                </Button> :
                                <Button type='submit' variant="contained" color={`error`}>
                                    Delete
                                </Button>}

                            <Button variant="outlined" color='info' onClick={onClose}>Close</Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default DeleteBlogs