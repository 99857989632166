import React, { useState } from "react";
import Layout from "../../components/layout";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AddBlogs from "../blogs/AddBlogs";
import UpdateBlogs from "../blogs/UpdateBlogs";
import Moment from "react-moment";
import useRead from "../../hooks/useRead";
import Errors from "../errors";
import Spinner from "../../components/spinner";
import { Alert, Snackbar } from "@mui/material";
import ReactPaginate from "react-paginate";
import Editor from "../../components/elements/Editor";
import DeleteBlogs from "../blogs/DeleteBlogs";
import { Link, useNavigate } from "react-router-dom";
import useEditMusic, { fetchSingleMusic } from "../../hooks/music/useEditMusic";
import DeleteMusic from "./DeleteMusic";

function DoNothingListing() {
  const initialData = {
    searchQuery: "",
    pageNo: 0,
    limit: 10,
  };
  const { queryData, paramsObject, setGetListParams } = useRead({
    url: "music/audio_listing",
    initialData,
  });
  // console.log(queryData?.data?.data);
  const [musicId, setMusicId] = useState(0); // Manage the state for musicId
  // const [moodId, setMoodId] = useState(0);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const handleSearchVal = (e) => {
    let value = e.target.value;
    let data = {
      ...paramsObject,
      pageNo: 0,
      [e.target.name]: value,
    };
    setGetListParams(data);
  };
  const handleOpenAddCoupon = () => {
    setOpenAddModal(true);
  };
  const handleModalClose = () => {
    setOpenAddModal(false);
    setIsOpenEditModal(false);
    setOpenDeleteModal(false);
  };
  const [singleBlogData, setSingleBlogData] = useState("");
  const handleOpenEditModal = (id) => {
    setIsOpenEditModal(true);
    let data = queryData.data.data.find((x) => x.id === id);
    setSingleBlogData(data);
  };
  const [categoryId, setCategoryId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (musicId) => {
    setOpenDeleteModal(true);
    setMusicId(musicId);
  };
  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false);
  const deletedSuccess = () => {
    handleModalClose();
    setIsDeletedSuccess(true);
  };
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      pageNo: val.selected,
    };
    setGetListParams(data);
  };
  const navigate = useNavigate();
  // const {
  //   data: fetchedMusicData,
  //   isLoading,
  //   error,
  // } = useQuery(["music", currentEditId], () => fetchSingleMusic(currentEditId), {
  //   enabled: !!currentEditId, // Only fetch data if currentEditId is defined
  //   onSuccess: () => {
  //     navigate(`/edit-do-nothing`)
  //   },
  // });
  const { fetchSingleMusic, updateMusic } = useEditMusic();
  return (
    <>
      <Layout>
        <div className="blogs">
          <div className="blog__head headingBorder__b">
            <h2 className="titleHeading">Do Nothing</h2>
          </div>
          <div className="blog_table py-5 space-y-5">
            <div className="flex items-center space-x-4">
              <div className="flex-1">
                <input
                  onChange={handleSearchVal}
                  type="text"
                  name="searchQuery"
                  placeholder="Search Music...."
                  className="input"
                />
              </div>
              <div>
                <Link to={"mood-category"}>
                  <Button variant="outlined" className="">
                    Mood Category
                  </Button>
                </Link>
              </div>
              <div>
                <Link to={"add-music"}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    className="primaryBtn-outlined"
                  >
                    Add Music
                  </Button>
                </Link>
              </div>
            </div>
            {queryData?.isLoading ? (
              <Spinner />
            ) : queryData?.status === "error" ? (
              <Errors errorObject={queryData.error} inline />
            ) : (
              <>
                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                  {queryData?.data?.data?.length > 0 ? (
                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                      <thead>
                        <tr className="text-left">
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                            Sr.no
                          </th>
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Music Title
                          </th>
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Status
                          </th>
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {queryData.data?.data?.map((item, idx) => {
                          const { audioName, musicId, status } = item;
                          return (
                            <tr key={musicId}>
                              <td className="border-dashed border-t border-gray-200 userId">
                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                  {idx + 1}
                                </span>
                              </td>
                              <td className="border-dashed border-t border-gray-200 firstName">
                                <span className="text-gray-700 px-6 py-3 text-center block text-lg">
                                  {audioName}
                                </span>
                              </td>

                              <td className="border-dashed border-t border-gray-200 emailAddress">
                                <div className="flex items-center justify-center">
                                  {status ? (
                                    <span className="px-4 py-1 font-semibold rounded bg-green-700 text-green-200">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="px-4 py-1 font-semibold rounded bg-red-700 text-red-200">
                                      Disabled
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td className="border-dashed border-t border-gray-200 phoneNumber">
                                <div className="flex items-center space-x-5 justify-center">
                                  <div>
                                    {/* <IconButton
                                    onClick={() => handleOpenEditModal(id)}
                                  > */}

                                    <Link to={`edit-do-nothing/${musicId}`}>
                                      <EditIcon />
                                    </Link>
                                    {/* </IconButton> */}
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleOpenDeleteModal(musicId)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="p-5 text-center text-gray-500">
                      No data found
                    </div>
                  )}
                </div>
                <div className=" my-7">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    initialPage={queryData.data?.page_no}
                    pageCount={Math.ceil(queryData.data?.total_count / 10)}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="flex items-center justify-center space-x-4"
                    pageLinkClassName="pageNumber"
                    previousLinkClassName="pageNumber"
                    nextLinkClassName="pageNumber"
                    activeLinkClassName="selectedPageNumber"
                    disabledClassName="lastPage"
                    disabledLinkClassName="lastPage"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
      {openAddModal && (
        <AddBlogs open={openAddModal} handleClose={handleModalClose} />
      )}
      {isOpenEditModal && (
        <UpdateBlogs
          singleBlogData={singleBlogData}
          open={isOpenEditModal}
          handleClose={handleModalClose}
        />
      )}
      <DeleteMusic
        url={"music/delete_audio"}
        queryKey={"music/audio_listing"}
        open={openDeleteModal}
        onClose={handleModalClose}
        musicId={musicId}
        onDelete={deletedSuccess}
      />
      <Snackbar
        open={isDeletedSuccess}
        autoHideDuration={6000}
        onClose={() => setIsDeletedSuccess(false)}
      >
        <Alert
          onClose={() => setIsDeletedSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Music Deleted Succesfully
        </Alert>
      </Snackbar>
    </>
  );
}

export default DoNothingListing;
