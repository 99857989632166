import Layout from '../../../components/layout'
import { Button } from '@mui/material'
import { useState } from 'react'
import { MdAdd } from 'react-icons/md';
import useReadUpdated from '../../../hooks/updated/useRead';
import useCreate from '../../../hooks/useCreate';
import { useDeleteDataFromServer } from '../../../hooks/useDelete';
import Spinner from '../../../components/spinner';

const Flavours = () => {
  let initialState = {
    pageNo: 0,
    limit: 20,
    search_query: ''
  }
  const handleSearchVal = (e) => {
    let value = e.target.value
    // setSearcVal(value)
  }
  const [flavourData, setFlavourData] = useState([])
  const { list } = useReadUpdated({
    url: 'flavourlisting',
    initialData: initialState,
    onSuccess: (res) => {
      setFlavourData(res.data)
    }
  })
  const handleAddTaxField = () => {
    let newField = {
      flavour_name: '',
      status: true
    }
    setFlavourData(prevData => [newField, ...prevData,])
  }
  const { setDataToServer } = useCreate({
    refreshUrl: 'flavourlisting',
    url: 'flavourcreate'
  })
  const updateTax = useCreate({
    refreshUrl: 'flavourlisting',
    url: 'flavourupdate'
  })
  const handleSubmitForm = (e, idx, tax_id) => {
    e.preventDefault()
    // check if ID exists then call edit service else call create service
    if (tax_id) {
      updateTax.setDataToServer.mutate(flavourData[idx])
    } else {
      setDataToServer.mutate(flavourData[idx])
    }
  }
  const handleChangeInput = (e, idx) => {
    let copyData = flavourData
    let $this = e.currentTarget
    copyData[idx][$this.name] = $this.value
    setFlavourData([...flavourData])
  }
  const { setDeleteData } = useDeleteDataFromServer('flavourdelete', 'flavourlisting', {
    onSuccesCB: () => { },
    onErrorCD: () => { }
})
  const handleDeleteTaxField = (fieldID, tax_id) => {
    if (tax_id) {
      let data = { tax_id }
      setDeleteData.mutate(data)
    } else {
      let copyData = flavourData
      copyData.splice(fieldID, 1)
      setFlavourData([...flavourData])
    }
  }
  return (
    <>
      <Layout>
        <div className='category'>
          <div className='category__head headingBorder__b'>
            <h2 className='titleHeading'>Flavours</h2>
          </div>
          <div className='category_table py-5 space-y-5'>
            <div className='flex items-center space-x-4'>
              <div className='flex-1'>
                <input onChange={handleSearchVal} type="text" placeholder='Search Flavour....' className='input' />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-4 gap-5'>
            <CreateFlavour onAddField={handleAddTaxField} />
            {flavourData.map((form, idx) => {
              const { id } = form
              return <FlavourForm key={idx} data={form}
              onChange={(e) => handleChangeInput(e, idx)}
              onDelete={() => handleDeleteTaxField(idx, id)}
              onSubmit={(e) => handleSubmitForm(e, idx, id)}
              />
            })}
          </div>
        </div>
      </Layout>

    </>
  )
}

const CreateFlavour = ({ onAddField }) => {
  return <button onClick={onAddField} className='border bg-white hover:border-solid hover:shadow-lg border-gray-600 cursor-pointer rounded-md border-dashed flex flex-col items-center justify-center select-none'>
    <MdAdd className='w-16 h-16 text-gray-600' />
    <span className='text-gray-600'>Add Flavour</span>
  </button>
}
const FlavourForm = ({ data, onDelete, onChange, onSubmit, isSaving, isDeleting }) => {
  return <div className='border border-gray-600 p-4 rounded bg-white'>
    <form className='space-y-4' onSubmit={onSubmit}>
      <div>
        <label htmlFor="Tax name" className='label'>Flavour name <small className="text-red-700">*</small></label>
        <input required onChange={onChange} value={data?.flavour_name} type="text" placeholder='Tax Name' name='flavour_name' className='input' />
      </div>
      <div className=' space-x-4 flex'>
        <Button type='submit' variant="contained" className='primaryBtn-contained w-full'>
          Save
          {isSaving && <Spinner className={' w-8 h-8 mx-0'} />}
        </Button>
        <Button onClick={onDelete} type='button' color="error" variant="outlined" className='primaryBtn-contained w-full font-semibold'>
          Delete
          {isDeleting && <Spinner className={' w-8 h-8 mx-0'} />}
        </Button>
      </div>
    </form>
  </div>
}

export default Flavours