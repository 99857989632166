import React from "react";
import Layout from "../../components/layout";
import CardWidgets from "../../components/widgets/CardWidgets";
import { useQuery } from "@tanstack/react-query";
import { getDashboardData } from "../../apis/dashboard";
import Spinner from "../../components/spinner";
import Errors from "../errors";
import AppWidget from "./AppWidget";
import { Link } from "react-router-dom";

function AppLanding() {
  const handleGetDashboardData = useQuery(["categoryList"], getDashboardData, {
    refetchOnWindowFocus: true,
  });

  const linkItems = [
    { title: "Do nothing", to: "do-nothing", border: "", description: "Description 1" },
    {
      title: "Calories",
      to: "calories",
      border: "border-class-2",
      description: "Description 2",
    },
    {
      title: "Exercise",
      to: "exercise",
      border: "border-class-2",
      description: "Description 2",
    },
    // Add more link items as needed
  ];

  return (
    <>
      <Layout>
        {handleGetDashboardData.isLoading ? (
          <Spinner />
        ) : handleGetDashboardData.status === "error" ? (
          <Errors errorObject={handleGetDashboardData.error} inline />
        ) : (
          <div className="grid grid-cols-4 gap-x-5 mt-8">
            {linkItems.map((item, index) => (
              <Link
                key={index}
                to={item.to}
                className={`cardwidgets__container shadow-md rounded-md px-6 border-b-4 ${item.border} bg-white`}
              >
                <div className="cardwidgets_head border-b">
                  <h2 className="text-gray-700"> {`${item.title}`}</h2>
                </div>
                <div className="cardwidgets_body py-6">
                  <div
                    className={`cardwidgets__counter border-4 ${item.border}`}
                  >
                    {/* <span className='text-gray-700 font-semibold text-3xl'>{formatCount(count,true,1)}</span> */}
                  </div>
                </div>
                <div className="cardwidgets_foot pb-6">
                  <p className="text-center text-lg text-gray-700 font-semibold capitalize">
                    {/* {item.description} */}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        )}
      </Layout>
    </>
  );
}

export default AppLanding;
