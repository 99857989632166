import React, { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUploader } from "react-drag-drop-files";
import Button from "@mui/material/Button";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { addProduct } from "../../apis/product";
import Spinner from "../../components/spinner";
import MuiAlert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import { GrAdd } from "react-icons/gr";
import Configurations from "../../components/section/configurations";
import { useNavigate, useParams } from "react-router-dom";
import useReadUpdated from "../../hooks/updated/useRead";
import ProductDetails from "../../components/elements/Editor/ProductDetails";
import useAddMusic from "../../hooks/music/useAddMusic";
import useAddCalorie from "../../hooks/calorie/useAddCalorie";
import useUpload from "../../hooks/useUpload";
import { TextField } from "@mui/material";
import useEditMusic from "../../hooks/music/useEditMusic";
import useEditCalorie from "../../hooks/calorie/useEditCalorie";

const fileTypes = ["JPG", "PNG", "JPEG"];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddFoodItem() {
  const { foodItemId } = useParams();
  const navigate = useNavigate();
  const productImageUpload = useRef(null);
  const queryClient = useQueryClient();
  const initialState = {
    foodItemId: "",
    title: "",
    calorieCount: "",
    avatarId: "",
    foodCategory: "",
    description: "",
    avatarPreview: null,
  };

  // const { fetchSingleMusic, updateMusic, handleEditForm } = useEditMusic();
  const { fetchSingleCalorie, updateCalorie, handleEditForm } =
    useEditCalorie();
  const [productData, setProductData] = useState(initialState);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { handleUpload: uploadAvatar } = useUpload({
    refreshUrl: "music/audio_listing",
    url: "upload/upload_media",
    onSuccess: (response) => {
      const { media_id, media_url } = response.data;
      if (media_id && media_url) {
        setProductData((prev) => ({
          ...prev,
          avatarId: media_id,
          avatarPreview: media_url,
        }));
      } else {
        console.error("Invalid response data:", response);
      }
    },
    onError: (error) => {
      console.error("Upload Error:", error);
      setIsError(true);
    },
  });
  const handleAvatarUpload = (file) => {
    uploadAvatar(file, "audio/avatar");
  };

  const handleCloseAndReset = () => {
    setProductData({
      foodItemId: "",
      title: "",
      calorieCount: "",
      avatarId: "",
      foodCategory: "",
      description: "",
      avatarPreview: null,
    });
    setIsError(false);
    setIsSuccess(false);
  };

  const handleAddFromData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setProductData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const { handleAddCalorie } = useAddCalorie();

  useEffect(() => {
    if (foodItemId) {
      fetchSingleCalorie(foodItemId)
        .then((data) => {
          setProductData({
            foodItemId,
            title: data?.data?.title || "",
            calorieCount: data?.data?.calorieCount || "",
            avatarId: data?.data?.avatarId || "",
            foodCategory: data?.data?.foodCategory || "",
            description: data?.data?.description || "",
            avatarPreview: data?.data?.avatarUrl || null,
          });
        })
        .catch(() => setIsError(true));
    }
  }, [foodItemId, fetchSingleCalorie]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (foodItemId) {
      handleEditForm(productData);
    } else {
      handleAddCalorie(e, productData);
    }
  };

  return (
    <>
      <div className="container_xxl bg-white">
        <div className="flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white">
          <div>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div>
            <h1 className="heading">Add Food Item</h1>
          </div>
        </div>
        <div className="form-body py-8">
          <form className="" autoComplete="off" onSubmit={handleSubmitForm}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="Title" className="label">
                  Title <small className="text-red-700">*</small>
                </label>
                <TextField
                  required
                  type="text"
                  placeholder="Title"
                  name="title"
                  className="input"
                  value={productData.title}
                  onChange={handleAddFromData}
                />
              </div>
              <div>
                <label htmlFor="Mood Name" className="label">
                  Calorie Count <small className="text-red-700">*</small>
                </label>
                <TextField
                  required
                  type="text"
                  placeholder="Calorie Count "
                  name="calorieCount"
                  className="input"
                  value={productData.calorieCount}
                  onChange={handleAddFromData}
                />
              </div>
              <div>
                <label htmlFor="foodCategory" className="label">
                  Food Category <small className="text-red-700">*</small>
                </label>
                <select
                  name="foodCategory"
                  required
                  value={productData.foodCategory}
                  onChange={handleAddFromData}
                  className="input"
                >
                  <option value="">--Select Food Category--</option>
                  <option value="breakfast">Breakfast</option>
                  <option value="lunch">Lunch</option>
                  <option value="dinner">Dinner</option>
                </select>
              </div>

              <div>
                <label htmlFor="Mood Name" className="label">
                  Description
                </label>
                <TextField
                  // required
                  type="text"
                  placeholder="Description"
                  name="description"
                  className="input"
                  value={productData.audioName}
                  onChange={handleAddFromData}
                />
              </div>

              <div>
                <div className="my-7 flex items-center justify-between border-b border-gray-400">
                  <div className="pb-3">
                    <h1 className="font-semibold text-2xl">Avatar</h1>
                    <small className="text-red-700">
                      Note: Image to be less than 1mb
                    </small>
                  </div>
                </div>
                <div className=" space-y-4">
                  {!productData.avatarPreview ? (
                    <FileUploader
                      name="avatarPreview"
                      types={fileTypes}
                      handleChange={handleAvatarUpload}
                      classes="file-uploader"
                      hoverTitle="Drop here"
                    />
                  ) : (
                    <div className="border border-dashed border-blue-700 rounded">
                      <div className="w-40 mx-auto py-4 text-center space-y-3">
                        <img src={productData.avatarPreview} alt="preview" />
                        <Button
                          variant="outlined"
                          onClick={() =>
                            setProductData((prev) => ({
                              ...prev,
                              avatarId: "",
                              avatarPreview: null,
                            }))
                          }
                        >
                          Re-Upload
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="text-center my-6">
              {handleAddCalorie.isLoading ? (
                <Spinner />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="primaryBtn-contained"
                >
                  Save Music
                </Button>
              )}
            </div>
          </form>
        </div>
        {/* {isError && (
          <Alert
            onClose={closeErrorMessage}
            className="mb-4"
            severity="error"
            sx={{ width: "100%" }}
          >
            {postProductDataToServer?.error?.message}, Please try again later
          </Alert>
        )}
        {isSuccess && (
          <Alert
            onClose={closeErrorMessage}
            className="mb-4"
            severity="success"
            sx={{ width: "100%" }}
          >
            Music Added Succesfully
          </Alert>
        )} */}
      </div>
    </>
  );
}

export default AddFoodItem;
