import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Button, Checkbox, TextField } from "@mui/material";
import toast from "react-hot-toast";
import useCreate from "../../../hooks/useCreate";

const MuscleModal = ({ open, handleClose, muscleId, initialMuscleData }) => {
  // console.log(initialMuscleData?.data);
  
  const [muscleFormData, setMuscleFormData] = useState({
    name: "",
    isActive: false,
  });
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // Hook for creating a mood
  const { setDataToServer: createMuscle } = useCreate({
    refreshUrl: "muscle/muscle_listing",
    url: "muscle/create_muscle",
    onSuccess: () => {
      toast.success("Muscle created successfully!");
      handleClose();
      setIsSuccess(true);
    },
    onError: () => {
      setIsError(true);
    },
  });

  // Hook for updating a mood
  const { setDataToServer: updateMuscle } = useCreate({
    refreshUrl: "muscle/muscle_listing",
    url: "muscle/update_muscle",
    onSuccess: () => {
      toast.success("Music updated successfully!");
      handleClose();
      setIsSuccess(true);
    },
    onError: () => {
      setIsError(true);
    },
  });

  // Set form data when editing
  useEffect(() => {
    if (muscleId && initialMuscleData) {
      setMuscleFormData({
        name: initialMuscleData.name || "",
        isActive: initialMuscleData.isActive || false,
      });
    } else {
      // Reset the form when adding new
      setMuscleFormData({
        name: "",
        isActive: false,
      });
    }
  }, [muscleId, initialMuscleData]);

  // Reset form when the modal closes
  useEffect(() => {
    if (!open) {
      setMuscleFormData({
        name: "",
        isActive: false,
      });
    }
  }, [open]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMuscleFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setMuscleFormData((prevState) => ({
      ...prevState,
      isActive: e.target.checked,
    }));
  };

  // Form submission logic
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!muscleFormData.name) {
      toast.error("Muscle Name is required.");
      return;
    }
    if (muscleId) {
      // Update mood
      updateMuscle.mutate({ muscleId: muscleId, ...muscleFormData });
    } else {
      // Create new mood
      createMuscle.mutate(muscleFormData);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="bg-white mx-auto mt-[20%] w-[600px] p-3">
        <form
          onSubmit={handleFormSubmit}
          className="modalForm grid grid-cols-2 items-end"
        >
          <div>
            <label htmlFor="Music Name" className="label">
              Muscle Name <small className="text-red-700">*</small>
            </label>
            <TextField
              required
              type="text"
              placeholder="Muscle Name"
              name="name"
              className="input"
              value={muscleFormData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>
              <Checkbox
                checked={muscleFormData.isActive}
                onChange={handleCheckboxChange}
              />
              Active
            </label>
          </div>
          <div className="mt-5">
            <Button
              type="submit"
              variant="contained"
              className="primaryBtn-contained"
            >
              {muscleId ? "Update" : "Add"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MuscleModal;
