import React from 'react'
import spinner from '../../assets/images/common/spinner.gif'

function Spinner({className}) {
    return (
        <div className={`w-14 h-14 mx-auto ${className}`}>
            <img src={spinner} alt="spinner" className='w-full h-full' />
        </div>

    )
}

export default Spinner