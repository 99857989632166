import React from "react";
import useCreate from "../useCreate";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const useAddCalorie = () => {
  const navigate = useNavigate();
  // Hook for creating a mood
  const { setDataToServer: createCalorie } = useCreate({
    refreshUrl: "food/food_item_listing",
    url: "food/create_food_items",
    onSuccess: () => {
        navigate(-1)
       
    },
    onError: () => {},
  });

  const handleAddCalorie = (e, data) => {
    e.preventDefault();
    // console.log(data);
    createCalorie.mutate(data);
  };

  return { handleAddCalorie, createCalorie };
};

export default useAddCalorie;
