import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from 'react'
import axios from "../apis/axios";
import { TOKEN_KEY } from "../config";
let TOKEN = sessionStorage.getItem(TOKEN_KEY);

function useCreate({
  refreshUrl = '',
  url='',
  onSuccess,
  onError
}) {
  const queryClient = useQueryClient()
  const setDataToServer = useMutation((data) => setUsers(data), {
    onError: (err) => {
      onError?.(err)
    },
    onSuccess: (data) => {
      {refreshUrl && queryClient.invalidateQueries(refreshUrl)}
      onSuccess?.(data)
    }
  })
  const setUsers = async (getFormData) => {
    let fd = new FormData()
    for (var key in getFormData) {
      if (Array.isArray(getFormData[key])) {
        fd.append(key, JSON.stringify(getFormData[key]));
      } else {
        fd.append(key, getFormData[key]);
      }
    }
    const response = await axios({
      method: "POST",
      data: fd,
      url: `/${url}`,
      headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data
  }
  return { setDataToServer }

}

export default useCreate