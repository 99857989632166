import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Spinner from "../../../components/spinner";
import { Alert, Snackbar } from "@mui/material";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

import Layout from "../../../components/layout";
import useRead from "../../../hooks/useRead";
import DeleteCalorie from "../DeleteCalorie";
import Errors from "../../errors";
import AddMoodModal from "../AddMoodModal";
import { useQuery } from "@tanstack/react-query";
import { fetchSingleMood } from "../MoodCategoryListing";
import MuscleModal from "./MuscleModal";
import axios from "../../../apis/axios";
import { TOKEN_KEY } from "../../../config";
import DeleteMuscleModal from "./DeleteMuscleModal";
const TOKEN = sessionStorage.getItem(TOKEN_KEY);
export const fetchSingleMuscle = async (muscleId) => {
    // Make a GET request with the Authorization header
    const response = await axios.get(`muscle/fetch_single_muscle?muscleId=${muscleId}`, {
      headers: {
        Authorization: TOKEN ? `Bearer ${TOKEN}` : "", // Include the token if available
      },
    });
  
    return response.data;
  };
function MuscleListing() {
  const initialData = {
    searchQuery: "",
    pageNo: 0,
    limit: 10,
  };
  const { queryData, paramsObject, setGetListParams } = useRead({
    url: "muscle/muscle_listing",
    initialData,
  });
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [singleMoodData, setSingleMoodData] = useState(null);
  const [muscleId, setMuscleId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleSearchVal = (e) => {
    let value = e.target.value;
    let data = {
      ...paramsObject,
      pageNo: 0,
      [e.target.name]: value,
    };
    setGetListParams(data);
  };
  const handleOpenAddCoupon = () => {
    setOpenAddModal(true);
  };
  const handleModalClose = () => {
    setOpenAddModal(false);
    setIsOpenEditModal(false);
    setOpenDeleteModal(false);
  };
  
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (muscleId) => {
    setOpenDeleteModal(true);
    setMuscleId(muscleId);
  };
  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false);
  const deletedSuccess = () => {
    handleModalClose();
    setIsDeletedSuccess(true);
  };
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      pageNo: val.selected,
    };
    setGetListParams(data);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [currentEditId, setCurrentEditId] = useState(null);

  const {
    data: fetchedMuscleData,
    isLoading,
    error,
  } = useQuery(["muscle", currentEditId], () => fetchSingleMuscle(currentEditId), {
    enabled: !!currentEditId, // Only fetch data if currentEditId is defined
    onSuccess: () => {
      setIsEditModalOpen(true);
    },
  });

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentEditId(null);
    setSingleMoodData(null); // Reset to ensure no leftover data is used
  };
  const handleOpenEditModal = (muscleId) => {
    setSingleMoodData(muscleId);
    setCurrentEditId(muscleId); // Set the current ID and trigger the query
    setIsEditModalOpen(true);
  };
  return (
    <>
      <Layout>
        <div className="blogs">
          <div className="blog__head headingBorder__b">
            <h2 className="titleHeading">Muscle</h2>
          </div>
          <div className="blog_table py-5 space-y-5">
            <div className="flex items-center space-x-4">
              <div className="flex-1">
                <input
                  onChange={handleSearchVal}
                  type="text"
                  name="searchQuery"
                  placeholder="Search Exercise...."
                  className="input"
                />
              </div>

              <div>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className="primaryBtn-outlined"
                  onClick={handleOpenModal}
                >
                  Add Muscle
                </Button>
                <MuscleModal
                  open={isModalOpen}
                  handleClose={handleCloseModal}
                />
              </div>
            </div>
            {queryData?.isLoading ? (
              <Spinner />
            ) : queryData?.status === "error" ? (
              <Errors errorObject={queryData.error} inline />
            ) : (
              <>
                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                  {queryData?.data?.data?.length > 0 ? (
                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                      <thead>
                        <tr className="text-left">
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                            Sr.no
                          </th>
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Muscle
                          </th>
                         
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Status
                          </th>
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {queryData?.data?.data?.map((item, idx) => {
                          const {
                            muscleId,
                            description,
                            foodCategory,
                            foodItemId,
                            status,
                            id,
                            title,
                            name,
                          } = item;
                          return (
                            <tr key={muscleId}>
                              <td className="border-dashed border-t border-gray-200 userId">
                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                  {idx + 1}
                                </span>
                              </td>
                              <td className="border-dashed border-t border-gray-200 firstName">
                                <span className="text-gray-700 px-6 py-3 text-center block text-lg">
                                  {name}
                                </span>
                              </td>
                            

                              <td className="border-dashed border-t border-gray-200 emailAddress">
                                <div className="flex items-center justify-center">
                                  {status ? (
                                    <span className="px-4 py-1 font-semibold rounded bg-green-700 text-green-200">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="px-4 py-1 font-semibold rounded bg-red-700 text-red-200">
                                      Disabled
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td className="border-dashed border-t border-gray-200 phoneNumber">
                                <div className="flex items-center space-x-5 justify-center">
                                  <div   onClick={() =>
                                        handleOpenEditModal(muscleId)
                                      }>
                                    {/* <IconButton
                                    onClick={() 
                                  > */}
                                   
                                      <EditIcon />
                                  
                                    {/* </IconButton> */}
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleOpenDeleteModal(muscleId)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="p-5 text-center text-gray-500">
                      No data found
                    </div>
                  )}
                </div>
                <div className=" my-7">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    initialPage={queryData.data?.page_no}
                    pageCount={Math.ceil(queryData.data?.total_count / 10)}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="flex items-center justify-center space-x-4"
                    pageLinkClassName="pageNumber"
                    previousLinkClassName="pageNumber"
                    nextLinkClassName="pageNumber"
                    activeLinkClassName="selectedPageNumber"
                    disabledClassName="lastPage"
                    disabledLinkClassName="lastPage"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
      <MuscleModal
        open={isEditModalOpen}
        handleClose={handleCloseEditModal}
        muscleId={currentEditId} // Pass the ID to the modal
        initialMuscleData={fetchedMuscleData?.data} // Pass the initial data for the mood
      />
      <DeleteMuscleModal
        url={"muscle/delete_muscle"}
        queryKey={"muscle/muscle_listing"}
        open={openDeleteModal}
        onClose={handleModalClose}
        muscleId={muscleId}
        onDelete={deletedSuccess}
      />
      <Snackbar
        open={isDeletedSuccess}
        autoHideDuration={6000}
        onClose={() => setIsDeletedSuccess(false)}
      >
        <Alert
          onClose={() => setIsDeletedSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Muscle Deleted Succesfully
        </Alert>
      </Snackbar>
    </>
  );
}

export default MuscleListing;
