import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../apis/axios";
import { TOKEN_KEY } from "../config";
let TOKEN = sessionStorage.getItem(TOKEN_KEY);


const useDeleteDataFromServer = (url, refreshUrl, { onSuccesCB, onErrorCD }) => {
  const queryClient = useQueryClient()
    const setDeleteData = useMutation((data) => deleteUser(data, url), {
        onError: (err) => {
            onErrorCD(err)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(refreshUrl)
            onSuccesCB()
        }
    })

    return { setDeleteData }

}

const deleteUser = async (getFormData, url) => {
  let fd = new FormData()
  for (var key in getFormData) {
      fd.append(key, getFormData[key]);
  }
  const response = await axios({
      method: "POST",
      data: fd,
      url: url,
      headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
  })
  return response.data
}


export { useDeleteDataFromServer, deleteUser }