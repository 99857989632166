import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Button, Checkbox, TextField } from "@mui/material";
import toast from "react-hot-toast";
import useCreate from "../../hooks/useCreate";

const AddMoodModal = ({ open, handleClose, moodId, initialMoodData }) => {
  // console.log(initialMoodData?.data);
  
  const [moodFormData, setMoodFormData] = useState({
    name: "",
    isActive: false,
  });
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // Hook for creating a mood
  const { setDataToServer: createMood } = useCreate({
    refreshUrl: "mood/mood_listing",
    url: "mood/create_mood",
    onSuccess: () => {
      toast.success("Mood created successfully!");
      handleClose();
      setIsSuccess(true);
    },
    onError: () => {
      setIsError(true);
    },
  });

  // Hook for updating a mood
  const { setDataToServer: updateMood } = useCreate({
    refreshUrl: "mood/mood_listing",
    url: "mood/update_mood",
    onSuccess: () => {
      toast.success("Mood updated successfully!");
      handleClose();
      setIsSuccess(true);
    },
    onError: () => {
      setIsError(true);
    },
  });

  // Set form data when editing
  useEffect(() => {
    if (moodId && initialMoodData) {
      setMoodFormData({
        name: initialMoodData.name || "",
        isActive: initialMoodData.isActive || false,
      });
    } else {
      // Reset the form when adding new
      setMoodFormData({
        name: "",
        isActive: false,
      });
    }
  }, [moodId, initialMoodData]);

  // Reset form when the modal closes
  useEffect(() => {
    if (!open) {
      setMoodFormData({
        name: "",
        isActive: false,
      });
    }
  }, [open]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMoodFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setMoodFormData((prevState) => ({
      ...prevState,
      isActive: e.target.checked,
    }));
  };

  // Form submission logic
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!moodFormData.name) {
      toast.error("Mood Name is required.");
      return;
    }
    if (moodId) {
      // Update mood
      updateMood.mutate({ moodId: moodId, ...moodFormData });
    } else {
      // Create new mood
      createMood.mutate(moodFormData);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="bg-white mx-auto mt-[20%] w-[600px] p-3">
        <form
          onSubmit={handleFormSubmit}
          className="modalForm grid grid-cols-2 items-end"
        >
          <div>
            <label htmlFor="Mood Name" className="label">
              Mood Name <small className="text-red-700">*</small>
            </label>
            <TextField
              required
              type="text"
              placeholder="Mood Name"
              name="name"
              className="input"
              value={moodFormData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>
              <Checkbox
                checked={moodFormData.isActive}
                onChange={handleCheckboxChange}
              />
              Active
            </label>
          </div>
          <div className="mt-5">
            <Button
              type="submit"
              variant="contained"
              className="primaryBtn-contained"
            >
              {moodId ? "Update" : "Add"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddMoodModal;
