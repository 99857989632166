import React, { useEffect, useRef, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileUploader } from "react-drag-drop-files";
import Button from '@mui/material/Button';
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { addProduct } from '../../apis/product';
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { GrAdd } from 'react-icons/gr'
import Configurations from '../../components/section/configurations';
import { useNavigate } from 'react-router-dom';
import useReadUpdated from '../../hooks/updated/useRead';
import ProductDetails from '../../components/elements/Editor/ProductDetails';

const fileTypes = ["JPG", "PNG", "JPEG"];
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function AddProduct() {
    const navigate = useNavigate();
    const productImageUpload = useRef(null)
    const queryClient = useQueryClient()
    const initialState = {
        product_name: '',
        mrp_price: '',
        selling_price: '',
        discount: '',
        stock: '',
        product_tag: '',
        description: '',
        product_form: '',
        package_type: '',
        product_purpose: [],
        flavour: '',
        hsn_code: '',
        veg_nonveg: '',
        product_thumbnail: '',
        product_thumbnail_preview: '',
        images_of_product: [],
        images_of_product_preview: [],
        apex_bifurcation: [],
        image_alt: '',
        tax_id: '',
        product_details: [],
    }
    const [productData, setProductData] = useState(initialState)
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const handleAddFromData = (e, file, isFileObject, name) => {
        let data;
        if (file) {
            if (isFileObject) {
                data = {
                    ...productData,
                    [name]: e
                }
            } else {
                let fileObject = e.target.files[0]
                data = {
                    ...productData,
                    [e.target.name]: fileObject
                }
            }
        } else {
            let value = e.target.value
            data = {
                ...productData,
                [e.target.name]: value
            }
        }
        setProductData(data)
    }
    useEffect(() => {
        if (!productData.product_thumbnail) return
        const objectUrl = URL.createObjectURL(productData.product_thumbnail)
        let data = {
            ...productData,
            product_thumbnail_preview: objectUrl
        }
        setProductData(data)
        return () => URL.revokeObjectURL(objectUrl)
    }, [productData.product_thumbnail])
    const handleAddFormFields = (type) => {
        const productArray = productData[type]
        if (type === 'productPriceDetails') {
            productArray.push({
                SKU: '',
                Price: '',
                Stock: '',
                Code: ''
            })
        } else {
            productArray.push('')
        }
        setProductData({ ...productData })
    }

    const handleDeleteFieldsFromForm = (type, idx) => {
        const productArray = productData[type]
        productArray.splice(idx, 1)
        setProductData({ ...productData })
    }
    const handleAddSubFromData = (e, type, idx) => {
        const productArray = productData[type]
        let value = e.target.value
        if (type === 'productPriceDetails') {
            productArray[idx][e.target.name] = value
        } else {
            productArray[idx] = value
        }
        setProductData({ ...productData })
    }
    const postProductDataToServer = useMutation((data) => addProduct(data), {
        onSuccess: (data) => {
            if (data.status === 200) {
                queryClient.invalidateQueries('productList')
                setIsSuccess(true)
                setTimeout(() => {
                    handleCloseAndReset()
                }, 2000)
            }
        },
        onError: () => {
            setIsError(true)
        }
    })
    const closeErrorMessage = () => {
        setIsError(false)
    }
    const handleProductSubmit = (e) => {
        e.preventDefault()
        // postProductDataToServer.mutate(productData)
        console.log(productData);
    }
    const handleCloseAndReset = () => {
        setProductData(initialState)
        closeErrorMessage()
    }
    const handleuploadAgain = () => {
        let data = {
            ...productData,
            product_thumbnail_preview: '',
            product_thumbnail: ''
        }
        setProductData(data)
    }
    useEffect(() => {
        isError && setTimeout(() => setIsError(false), [4000])
    }, [isError])


    const [openUploadImagesModal, setOpenUploadImagesModal] = useState(false)
    const handleOpenUploadModal = () => {
        setOpenUploadImagesModal(true)
    }
    const handleCloseUploadImageModal = () => {
        setOpenUploadImagesModal(false)
    }
    const handleClickOpenFileDialog = () => {
        productImageUpload.current.click()
    }
    const handleUploadImages = (e) => {
        let previewImag = []
        for (let i = 0; i < e.target.files.length; i++) {
            previewImag.push(URL.createObjectURL(e.target.files[i]))
        }
        let data = {
            ...productData,
            images_of_product: [...productData.images_of_product, ...e.target.files],
            images_of_product_preview: [...productData.images_of_product_preview, ...previewImag]
        }
        setProductData(data)
    }

    const handleRemoveSelectedProductImage = (e, id) => {
        e.stopPropagation();
        let predData = productData
        predData.images_of_product_preview.splice(id, 1)
        predData.images_of_product.splice(id, 1)
        setProductData({ ...productData })
    }

    const handleProductPurpose = (e) => {
        let $this = e.currentTarget
        let id = +$this.getAttribute('data-id')
        let data
        if ($this.checked) {
            data = {
                ...productData,
                [$this.name]: [...productData.product_purpose, id]
            }
        } else {
            data = {
                ...productData,
                [$this.name]: productData.product_purpose.filter(x => x !== id)
            }
        }
        setProductData(data)
    }
    const [openConfigModal, setOpenConfigModal] = useState(false)
    const handleOpenConfigurationModal = () => {
        setOpenConfigModal(true)
    }

    let initialTaxState = {
        pageNo: 0,
        limit: 20,
        search_query: ''
    }
    const taxList = useReadUpdated({
        url: 'getTaxList',
        initialData: initialTaxState,
    })
    return (
        <>
            <div className='container_xxl bg-white'>
                <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading'>Add Product</h1>
                    </div>
                </div>
                <div className='form-body py-8'>
                    <form className='' autoComplete='off' onSubmit={handleProductSubmit}>
                        <div className='grid grid-cols-1 gap-4'>
                            <div>
                                <label htmlFor="Product Name" className='label'>Product Name <small className="text-red-700">*</small></label>
                                <input required type="text" placeholder='Product Name' name='product_name' className='input' value={productData.product_name} onChange={handleAddFromData} />
                            </div>
                            <div>
                                <label htmlFor="Product Name" className='label'>Product Description</label>
                                <textarea className='input min-h-[10rem]' placeholder='Description' name='description' value={productData.description} onChange={handleAddFromData}></textarea>
                            </div>
                        </div>
                        <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Product Details</h1>
                            </div>
                            <div className=' space-y-7'>
                                <div className={`grid grid-cols-5 gap-4`}>
                                    <div>
                                        <label className='label'>MRP Price</label>
                                        <input type="text" placeholder='MRP Price' name='mrp_price' className='input' value={productData.mrp_price} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label className='label'>Selling Price</label>
                                        <input type="text" placeholder='Selling Price' name='selling_price' className='input' value={productData.selling_price} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label className='label'>Discount</label>
                                        <input type="text" placeholder='Discount' name='discount' className='input' value={productData.discount} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label className='label'>Stock</label>
                                        <input type="text" placeholder='Stock' name='stock' className='input' value={productData.stock} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label className='label'>Product Tag</label>
                                        <select name="product_tag" className='input' value={productData.product_tag} onChange={handleAddFromData}>
                                            <option value="">--Select--</option>
                                            <option value="bestseller">Bestseller</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className='label'>Product Form</label>
                                        <input type="text" placeholder='Product form' name='product_form' className='input' value={productData.product_form} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label className='label'>Packaging Type</label>
                                        <input type="text" placeholder='Package type' name='package_type' className='input' value={productData.package_type} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label className='label'>HSN Code</label>
                                        <input type="text" placeholder='HSN code' name='hsn_code' className='input' value={productData.hsn_code} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label className='label'>Is Non-Veg?</label>
                                        <select name="veg_nonveg" className='input' value={productData.veg_nonveg} onChange={handleAddFromData}>
                                            <option value="">--Select--</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className='label'>Tax</label>
                                        <select name="tax_id" className='input' value={productData.tax_id} onChange={handleAddFromData}>
                                            <option value="">--Select--</option>
                                            {taxList.list.data?.data.map((tax) => {
                                                const { tax_id, value } = tax
                                                return <option value={tax_id}>{value}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div>
                                        <label className='label'>Product Purpose</label>
                                        <div className=' space-x-4 pt-2'>
                                            {['body', 'mind', 'soul'].map((puroseArr, idx) => {
                                                return <label key={idx} className='border border-gray-300 rounded p-2 space-x-2 cursor-pointer select-none'>
                                                    <input data-id={idx + 1} type="checkbox" name="product_purpose" onChange={handleProductPurpose} />
                                                    <span className=' capitalize'>{puroseArr}</span>
                                                </label>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ProductDetails productData={productData}
                            setProductData={setProductData} />

                        {/* <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>About Product</h1>
                                <div onClick={() => handleAddFormFields('about')}>
                                    <IconButton>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className=' space-y-4'>
                                {productData?.about.map((object, idx) => {
                                    return <div className='grid grid-cols-1 gap-4' key={idx}>
                                        <div className='flex'>
                                            <input type="text" placeholder='About' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'about', idx)} />
                                            {idx >= 1 &&
                                                <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('about', idx)}>
                                                    <IconButton color="error" aria-label="add an delete" size="large">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>

                        <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Who can use this product</h1>
                                <div onClick={() => handleAddFormFields('who_can_use_this_product')}>
                                    <IconButton>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className=' space-y-4'>
                                {productData?.who_can_use_this_product.map((object, idx) => {
                                    return <div className='grid grid-cols-1 gap-4' key={idx}>
                                        <div className='flex'>
                                            <input type="text" placeholder='Enter who can use the product' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'who_can_use_this_product', idx)} />
                                            {idx >= 1 &&
                                                <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('who_can_use_this_product', idx)}>
                                                    <IconButton color="error" aria-label="add an delete" size="large">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>

                        <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Benefits</h1>
                                <div onClick={() => handleAddFormFields('benefits')}>
                                    <IconButton>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className=' space-y-4'>
                                {productData?.benefits.map((object, idx) => {
                                    return <div className='grid grid-cols-1 gap-4' key={idx}>
                                        <div className='flex'>
                                            <input type="text" placeholder='Benefits' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'benefits', idx)} />
                                            {idx >= 1 &&
                                                <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('benefits', idx)}>
                                                    <IconButton color="error" aria-label="add an delete" size="large">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>

                        <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Apex Body-Mind-soul bifurcation</h1>
                                <div onClick={() => handleAddFormFields('apex_bifurcation')}>
                                    <IconButton>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className=' space-y-4'>
                                {productData?.apex_bifurcation.map((object, idx) => {
                                    return <div className='grid grid-cols-1 gap-4' key={idx}>
                                        <div className='flex'>
                                            <input type="text" placeholder='Apex Body-Mind-soul bifurcation' name='apex_bifurcation' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'apex_bifurcation', idx)} />
                                            {idx >= 1 &&
                                                <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('apex_bifurcation', idx)}>
                                                    <IconButton color="error" aria-label="add an delete" size="large">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>

                        <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Direction for use</h1>
                                <div onClick={() => handleAddFormFields('how_to_use')}>
                                    <IconButton>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className=' space-y-4'>
                                {productData?.how_to_use.map((object, idx) => {
                                    return <div className='grid grid-cols-1 gap-4' key={idx}>
                                        <div className='flex'>
                                            <input type="text" placeholder='Direction for use' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'how_to_use', idx)} />
                                            {idx >= 1 &&
                                                <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('how_to_use', idx)}>
                                                    <IconButton color="error" aria-label="add an delete" size="large">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>

                        <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Callouts/USP for artworks</h1>
                                <div onClick={() => handleAddFormFields('callouts_usp')}>
                                    <IconButton>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className=' space-y-4'>
                                {productData?.callouts_usp.map((object, idx) => {
                                    return <div className='grid grid-cols-1 gap-4' key={idx}>
                                        <div className='flex'>
                                            <input type="text" placeholder='Callouts/USP for artworks' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'callouts_usp', idx)} />
                                            {idx >= 1 &&
                                                <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('callouts_usp', idx)}>
                                                    <IconButton color="error" aria-label="add an delete" size="large">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>

                        <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Competition</h1>
                                <div onClick={() => handleAddFormFields('competition')}>
                                    <IconButton>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className=' space-y-4'>
                                {productData?.competition.map((object, idx) => {
                                    return <div className='grid grid-cols-1 gap-4' key={idx}>
                                        <div className='flex'>
                                            <input type="text" placeholder='Competition' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'competition', idx)} />
                                            {idx >= 1 &&
                                                <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('competition', idx)}>
                                                    <IconButton color="error" aria-label="add an delete" size="large">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div> */}

                        <div className='grid grid-cols-2 gap-4'>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <div className='pb-3'>
                                        <h1 className='font-semibold text-2xl'>Thumbnail of product</h1>
                                        <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                    </div>
                                </div>
                                <div className=' space-y-4'>
                                    {!productData.product_thumbnail_preview ?
                                        <FileUploader name="product_thumbnail" types={fileTypes} handleChange={(e) => handleAddFromData(e, true, true, 'product_thumbnail')} classes="file-uploader" hoverTitle='Drop here' />
                                        : <div className=' border border-dashed border-blue-700 rounded'>
                                            <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                                                <img src={productData.product_thumbnail_preview} alt="preview" />
                                                <Button variant="outlined" onClick={handleuploadAgain}>Re-Upload</Button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <div className='pb-3'>
                                        <h1 className='font-semibold text-2xl'>Images of product</h1>
                                        <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                    </div>
                                </div>
                                <div className='flex items-center justify-center mt-12'>
                                    <Button onClick={handleOpenUploadModal} variant="contained" color='success' className='primaryBtn-contained'>Add Images</Button>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="Image Alt" className='label'>Image alt</label>
                                <input type="text" placeholder='Enter image alt' value={productData.image_alt} onChange={handleAddFromData} name='image_alt' className='input' />
                            </div>
                        </div>
                        <div className='text-center my-6'>
                            {postProductDataToServer.isLoading ?
                                <Spinner /> :
                                <Button type='submit' variant="contained" className='primaryBtn-contained'>
                                    Save Product
                                </Button>}
                        </div>
                    </form>
                </div>
                {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{postProductDataToServer?.error?.message}, Please try again later</Alert>}
                {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Product Added Succesfully</Alert>}
            </div>
            <Modal open={openUploadImagesModal} onClose={handleCloseUploadImageModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-full absolute top-4 left-1/2 -translate-x-1/2'>
                    <div className=' max-w-5xl mx-auto bg-white'>
                        <div className=' divide-y'>
                            <div className="head-modal">
                                <div className='flex items-center space-x-4 px-6'>
                                    <div className="w-7 h-7 cursor-pointer" onClick={handleCloseUploadImageModal}>
                                        <svg className='w-full h-full' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                        </svg>
                                    </div>
                                    <h2 className='heading text-3xl'>Upload Product Images</h2>
                                </div>
                            </div>
                            <div className="body-modal py-4 px-6">
                                <div className='max-h-[20rem] min-h-[20rem] overflow-auto  relative'>
                                    <div className='imagePreview__container relative z-10'>
                                        <div className="grid grid-cols-4 gap-4 ">
                                            {productData.images_of_product_preview.map((img, idx) => {
                                                return <div className=' w-full  h-48 overflow-hidden border p-2 relative cursor-default' key={idx}>
                                                    <div className='w-6 h-6 cursor-pointer float-right' onClick={(e) => handleRemoveSelectedProductImage(e, idx)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </div>
                                                    <img src={img} alt="img" className='w-full h-full object-contain' />
                                                </div>
                                            })}
                                            <div onClick={handleClickOpenFileDialog} className='w-full h-48 cursor-pointer select-none border-2 rounded-md border-dashed border-gray-400 flex flex-col items-center justify-center hover:shadow-lg'>
                                                <GrAdd className=' w-16 h-16 text-gray-400' />
                                                <small>Add Product Image</small>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {productData.images_of_product_preview.length <= 0 && <span className='text-gray-400 text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>Click here to Upload</span>} */}
                                </div>
                                <input type="file" className='hidden' ref={productImageUpload} onChange={handleUploadImages} multiple accept="image/jpeg, image/png, image/jpg" />
                            </div>
                            <div className="foot-modal py-4 text-center">
                                <Button variant="contained" className='primaryBtn-contained px-10' onClick={handleCloseUploadImageModal}>Save Images</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddProduct