import React from "react";
import useCreate from "../useCreate";
import useRead from "../useRead";

const useMuscleListing = () => {
  // Hook for creating a mood
  const initialData = {
    searchQuery: "",
    pageNo: 0,
    limit: 10,
  };
  const { queryData, paramsObject, setGetListParams } = useRead({
    url: "muscle/muscle_listing",
    initialData,
  });


  return {queryData,paramsObject,setGetListParams};
};

export default useMuscleListing;
