import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";
import Cookies from "universal-cookie";

// const cookies = new Cookies();
let token = sessionStorage.getItem(TOKEN_KEY);
export const addMusic = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    for (var key in data) {
        if (key === 'images_of_product') {
            for (let i = 0; i < data['images_of_product'].length; i++) {
                fd.append('images_of_product[]', data['images_of_product'][i]);
            }
        }
        else if (Array.isArray(data[key])) {
            fd.append(key, JSON.stringify(data[key]));
        } else {
            fd.append(key, data[key]);
        }
    }
    let res = await axios({ method: "POST", data: fd, url: `${API_ENDPOINT}music/create_audio`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};