import React from 'react'
import Layout from '../../components/layout'
import { MdAdd } from 'react-icons/md';
import { Button } from '@mui/material';
import { useState } from 'react';
import Spinner from '../../components/spinner';
import useReadUpdated from '../../hooks/updated/useRead';
import useCreate from '../../hooks/useCreate';
import { useDeleteDataFromServer } from '../../hooks/useDelete';

const Taxes = () => {
    let initialState = {
        pageNo: 0,
        limit: 20,
        search_query: ''
    }
    const [taxData, setTaxData] = useState([])
    const handleAddTaxField = () => {
        let newField = {
            name: '',
            value: '',
        }
        setTaxData(prevData => [newField, ...prevData,])
    }

    const { list } = useReadUpdated({
        url: 'getTaxList',
        initialData: initialState,
        onSuccess: (res) => {
            setTaxData(res.data)
        }
    })
    const handleChangeInput = (e, idx) => {
        let copyData = taxData
        let $this = e.currentTarget
        copyData[idx][$this.name] = $this.value
        setTaxData([...taxData])
    }
    const handleDeleteTaxField = (fieldID,tax_id) => {
        if(tax_id){
            let data = {tax_id}
            setDeleteData.mutate(data)
        }else{
            let copyData = taxData
            copyData.splice(fieldID, 1)
            setTaxData([...taxData])
        }
    }

    const { setDataToServer } = useCreate({
        refreshUrl: 'getTaxList',
        url: 'createTax'
    })
    const updateTax = useCreate({
        refreshUrl: 'getTaxList',
        url: 'updateTax'
    })
    const { setDeleteData } = useDeleteDataFromServer('deleteTax', 'getTaxList', {
        onSuccesCB: () => { },
        onErrorCD: () => { }
    })
    const handleSubmitForm = (e, idx, tax_id) => {
        e.preventDefault()
        // check if ID exists then call edit service else call create service
        if (tax_id) {
            updateTax.setDataToServer.mutate(taxData[idx])
        } else {
            setDataToServer.mutate(taxData[idx])
        }
    }
    return (
        <>
            <Layout>
                <div className='category'>
                    <div className='category__head headingBorder__b'>
                        <h2 className='titleHeading'>Taxes</h2>
                    </div>
                    <div className='category_table py-5 space-y-5'>
                        {list.isLoading
                            ? <Spinner />
                            : <>
                                <div className='flex-1'>
                                    <input type="text" placeholder='Search Taxes....' className='input' />
                                </div>
                                <div className='grid grid-cols-4 gap-5'>
                                    <CreateTax onAddField={handleAddTaxField} />
                                    {taxData.map((form, idx) => {
                                        const { tax_id } = form
                                        return <TaxForm
                                            key={idx}
                                            data={form}
                                            onChange={(e) => handleChangeInput(e, idx)}
                                            onDelete={() => handleDeleteTaxField(idx,tax_id)}
                                            onSubmit={(e) => handleSubmitForm(e, idx, tax_id)}
                                        />
                                    })}
                                </div>
                            </>}
                    </div>
                </div>
            </Layout>
        </>
    )
}


const CreateTax = ({ onAddField }) => {
    return <button onClick={onAddField} className='border bg-white hover:border-solid hover:shadow-lg border-gray-600 cursor-pointer rounded-md border-dashed flex flex-col items-center justify-center select-none h-[15.7rem]'>
        <MdAdd className='w-16 h-16 text-gray-600' />
        <span className='text-gray-600'>Add Tax</span>
    </button>
}


const TaxForm = ({ data, onDelete, onChange, onSubmit, isSaving, isDeleting }) => {
    return <div className='border border-gray-600 p-4 rounded bg-white'>
        <form className='space-y-4' onSubmit={onSubmit}>
            <div>
                <label htmlFor="Tax name" className='label'>Tax name <small className="text-red-700">*</small></label>
                <input required onChange={onChange} value={data.name} type="text" placeholder='Tax Name' name='name' className='input' />
            </div>
            <div>
                <label htmlFor="Tax value" className='label'>Tax value <small className="text-red-700">*</small></label>
                <input required onChange={onChange} value={data.value} type="number" placeholder='Tax value' name='value' className='input' />
            </div>
            <div className=' space-x-4 flex'>
                <Button type='submit' variant="contained" className='primaryBtn-contained w-full'>
                    Save
                    {isSaving && <Spinner className={' w-8 h-8 mx-0'} />}
                </Button>
                <Button onClick={onDelete} type='button' color="error" variant="outlined" className='primaryBtn-contained w-full font-semibold'>
                    Delete
                    {isDeleting && <Spinner className={' w-8 h-8 mx-0'} />}
                </Button>
            </div>
        </form>
    </div>
}

export default Taxes