import React from "react";
import useCreate from "../useCreate";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const useAddMusic = () => {
  const navigate = useNavigate();
  // Hook for creating a mood
  const { setDataToServer: createMusic } = useCreate({
    refreshUrl: "music/audio_listing",
    url: "music/create_audio",
    onSuccess: () => {
        navigate(-1)
       
    },
    onError: () => {},
  });

  const handleAddMusic = (e, data) => {
    e.preventDefault();
    // console.log(data);
    createMusic.mutate(data);
  };

  return { handleAddMusic, createMusic };
};

export default useAddMusic;
