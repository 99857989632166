import React, { useState } from "react";
import Layout from "../../components/layout";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AddBlogs from "../blogs/AddBlogs";
import UpdateBlogs from "../blogs/UpdateBlogs";
import Moment from "react-moment";
import useRead from "../../hooks/useRead";
import Errors from "../errors";
import Spinner from "../../components/spinner";
import { Alert, Snackbar } from "@mui/material";
import ReactPaginate from "react-paginate";
import Editor from "../../components/elements/Editor";
import DeleteBlogs from "../blogs/DeleteBlogs";
import { Link } from "react-router-dom";
import AddMoodModal from "./AddMoodModal";

function CaloriesMoodCategory() {
  const initialData = {
    searchQuery: "",
    pageNo: 0,
    limit: 10,
  };
  const { queryData, paramsObject, setGetListParams } = useRead({
    url: "blogsList",
    initialData,
  });
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const handleSearchVal = (e) => {
    let value = e.target.value;
    let data = {
      ...paramsObject,
      pageNo: 0,
      [e.target.name]: value,
    };
    setGetListParams(data);
  };
  const handleOpenAddCoupon = () => {
    setOpenAddModal(true);
  };
  const handleModalClose = () => {
    setOpenAddModal(false);
    setIsOpenEditModal(false);
    setOpenDeleteModal(false);
  };
  const [singleBlogData, setSingleBlogData] = useState("");
  const handleOpenEditModal = (id) => {
    setIsOpenEditModal(true);
    let data = queryData.data.data.find((x) => x.id === id);
    setSingleBlogData(data);
  };
  const [categoryId, setCategoryId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setCategoryId(id);
  };
  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false);
  const deletedSuccess = () => {
    handleModalClose();
    setIsDeletedSuccess(true);
  };
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      pageNo: val.selected,
    };
    setGetListParams(data);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Layout>
        <div className="blogs">
          <div className="blog__head headingBorder__b">
            <h2 className="titleHeading">Mood Category</h2>
          </div>
          <div className="blog_table py-5 space-y-5">
            <div className="flex items-center space-x-4">
              <div className="flex-1">
                <input
                  onChange={handleSearchVal}
                  type="text"
                  name="searchQuery"
                  placeholder="Search Music...."
                  className="input"
                />
              </div>

              <div>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className="primaryBtn-outlined"
                  onClick={handleOpenModal}
                >
                  Add Mood
                </Button>
                <AddMoodModal open={isModalOpen} handleClose={handleCloseModal} />
              </div>
            </div>
            {queryData?.isLoading ? (
              <Spinner />
            ) : queryData?.status === "error" ? (
              <Errors errorObject={queryData.error} inline />
            ) : (
              <>
                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                  <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                    <thead>
                      <tr className="text-left">
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                          Sr.no
                        </th>
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                          Music Title
                        </th>
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                          Category
                        </th>
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                          Created On
                        </th>
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                          Status
                        </th>
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {queryData.data?.data?.map((item, idx) => {
                        const { blogTitle, id, blogStatus, blogPublishedAt } =
                          item;
                        return (
                          <tr>
                            <td className="border-dashed border-t border-gray-200 userId">
                              <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                {idx + 1}
                              </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 firstName">
                              <span className="text-gray-700 px-6 py-3 text-center block text-lg">
                                {blogTitle}
                              </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 firstName">
                              <span className="text-gray-700 px-6 py-3 text-center block text-lg">
                                Category Name
                              </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 firstName">
                              <span className="text-gray-700 px-6 py-3 text-center block text-lg">
                                <Moment unix format={"MMM Do YY"}>
                                  {blogPublishedAt}
                                </Moment>
                              </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 emailAddress">
                              <div className="flex items-center justify-center">
                                {blogStatus ? (
                                  <span className="px-4 py-1 font-semibold rounded bg-green-700 text-green-200">
                                    Active
                                  </span>
                                ) : (
                                  <span className="px-4 py-1 font-semibold rounded bg-red-700 text-red-200">
                                    Disabled
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="border-dashed border-t border-gray-200 phoneNumber">
                              <div className="flex items-center space-x-5 justify-center">
                                <div>
                                 
                                    <EditIcon />
                                 
                                </div>
                                <div onClick={() => handleOpenDeleteModal(id)}>
                                  <IconButton>
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className=" my-7">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    initialPage={queryData.data?.page_no}
                    pageCount={Math.ceil(queryData.data?.total_count / 10)}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="flex items-center justify-center space-x-4"
                    pageLinkClassName="pageNumber"
                    previousLinkClassName="pageNumber"
                    nextLinkClassName="pageNumber"
                    activeLinkClassName="selectedPageNumber"
                    disabledClassName="lastPage"
                    disabledLinkClassName="lastPage"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
      {openAddModal && (
        <AddBlogs open={openAddModal} handleClose={handleModalClose} />
      )}
      {isOpenEditModal && (
        <UpdateBlogs
          singleBlogData={singleBlogData}
          open={isOpenEditModal}
          handleClose={handleModalClose}
        />
      )}
      <DeleteBlogs
        url={"blogsDelete"}
        queryKey={"blogsList"}
        open={openDeleteModal}
        onClose={handleModalClose}
        id={categoryId}
        onDelete={deletedSuccess}
      />
      <Snackbar
        open={isDeletedSuccess}
        autoHideDuration={6000}
        onClose={() => setIsDeletedSuccess(false)}
      >
        <Alert
          onClose={() => setIsDeletedSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Blog Deleted Succesfully
        </Alert>
      </Snackbar>
    </>
  );
}

export default CaloriesMoodCategory;
