// hooks/music/useEditMusic.js
import React from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
import useCreate from "../useCreate"; // Adjust the path as necessary
import { TOKEN_KEY } from "../../config";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../apis/axios";

const TOKEN = sessionStorage.getItem(TOKEN_KEY);

export const fetchSingleMusic = async (musicId) => {
  try {
    // Ensure that the URL includes the musicId for fetching specific music details
    const response = await axios.get(
      `music/fetch_single_audio?musicId=${musicId}`,
      {
        headers: {
          Authorization: TOKEN ? `Bearer ${TOKEN}` : "",
        },
      }
    );

    // Return the data from the response
    return response.data;
  } catch (error) {
    // Handle errors appropriately
    toast.error("Error fetching music details!");
    console.error("Error fetching music details:", error);
    throw error;
  }
};
// const { musicId } = useParams();
const useEditMusic = () => {
    const navigate = useNavigate();
  const { setDataToServer: updateMusic } = useCreate({
    refreshUrl: "music/audio_listing",
    url: "music/update_audio",
    onSuccess: () => {
      toast.success("Music updated successfully!");
    },
    onError: () => {
      toast.error("Error updating music!");
    },
  });

  const handleEditForm = (data, onSuccess, onError) => {
    updateMusic.mutate(data, {
      onSuccess: (response) => {
        toast.success("Music updated successfully!");
        if (onSuccess) onSuccess(response); // Call custom onSuccess if provided
        navigate(-1);
      },
      onError: (error) => {
        toast.error("Error updating music!");
        if (onError) onError(error); // Call custom onError if provided
      },
    });
  };

  return { fetchSingleMusic, updateMusic, handleEditForm };
};

export default useEditMusic;
