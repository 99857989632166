import Layout from '../../components/layout'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconButton from '@mui/material/IconButton';
import { useQuery, useMutation } from "@tanstack/react-query";
import { getOrderPdf, getOrdersList } from '../../apis/orders';
import Spinner from '../../components/spinner';
import Errors from '../errors';
import Moment from 'react-moment';
import { useTableSearch } from '../../hooks/useTableSearch';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import useRead from '../../hooks/useRead';
import {useSearchParams} from 'react-router-dom'
function Orders() {
    const [searchParams, setSearchParams] = useSearchParams()
    const statusColors = {
        0: "bg-red-100 text-red-600",
        1: "bg-green-100 text-green-600",
        2: "bg-yellow-100 text-yellow-600",
        3: "bg-red-100 text-red-600",
    }
    const initialState = {
        limit: searchParams.get('limit') || 10,
        pageNo: searchParams.get('pageNo') || 0,
        paymentModeFilter: searchParams.get('paymentModeFilter') || 1,
        searchQuery: searchParams.get('searchQuery') || ''
    }

    const [orderState, setOrderState] = useState(initialState)
    const [dataCount, setDataCount] = useState(0)
    const handleGetOrdersList = useQuery(['ordersList', orderState], () => getOrdersList(orderState), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onSuccess: (response) => {
            const { total_count } = response.data;
            setDataCount(Math.ceil(total_count / orderState.limit))
        }
    })
    const handleSearchVal = (e) => {
        let value = e.target.value
        let data = {
            ...orderState,
            [e.target.name]: value
        }
        setOrderState(data)
        setSearchParams(data)
    }

    const orderReportDownload = useMutation((data) => getOrderPdf(data), {
        onSuccess: (data) => {
            console.log(data);
            let url = window.URL.createObjectURL(data.data);
            Object.assign(document.createElement('a'), {
                target: '_blank',
                rel: 'noopener noreferrer',
                href: url,
            }).click();
        },
        onError: () => {
        }
    })

    const handleViewOrderPdf = (id) => {
        let data = {
            id: id
        }
        orderReportDownload.mutate(data)
    }

    const handlePageClick = (val) => {
        let data = {
            ...orderState,
            pageNo: val.selected
        }
        setOrderState(data)
        setSearchParams(data)
    }

    const { queryData} = useRead({
        method: "GET",
        url: "paymentStatusList"
    })

    const handleFilterStatus = (e) => {
        let data = {
          ...orderState,
          paymentModeFilter:e.target.value
        }
        setOrderState(data)
        setSearchParams(data)
      }
    return (
        <>
            <Layout>
                <div className='category'>
                    <div className='category__head headingBorder__b'>
                        <h2 className='titleHeading'>Orders</h2>
                    </div>
                    {handleGetOrdersList?.data?.data.data?.length <= 0 
                    ? <p className=' text-center font-semibold text-gray-700 mt-10 text-2xl'>No Orders Found</p> 
                    : 
                        <div className='category_table py-5 space-y-5'>
                            <div className='flex items-center space-x-4'>
                                <div className='flex-1'>
                                    <input onChange={handleSearchVal} value={orderState.searchQuery} name='searchQuery' type="text" placeholder='Search Order By Email......' className='input' />
                                </div>
                            </div>
                            <div className="w-full flex justify-end text-right items-center px-10 space-x-5">
                                <h1>Filter: </h1>
                                <div>
                                    <select value={orderState.paymentModeFilter} onChange={(e) => handleFilterStatus(e)} className={`px-4 py-1 rounded capitalize text-sm ` + statusColors[orderState.paymentModeFilter]}>
                                        {
                                            queryData?.data?.data?.map((status) => {
                                                const { status_id, payment_status } = status
                                                return (
                                                    <>
                                                        <option value={status_id} className={statusColors[status_id]}>{payment_status}</option>
                                                    </>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            {handleGetOrdersList.isLoading ? <Spinner />
                                : handleGetOrdersList.status === "error" ? <Errors errorObject={handleGetOrdersList.error} inline /> :
                                    <><div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                            <thead>
                                                <tr className="text-left">
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                        Sr.no
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Customer Phone
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Order Date
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Total Sum
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Reference No
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Order State
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        View
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {handleGetOrdersList?.data?.data.data?.map((orderData, idx) => {
                                                    const { order_id, total_sum, order_token, order_state, ordered_at, customer_id, payment_status, status_id } = orderData
                                                    return <tr key={idx}>
                                                        <td className="border-dashed border-t border-gray-200 userId">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                                {(orderState.limit * (orderState.pageNo + 1)) - (orderState.limit - (idx + 1))}
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 text-center firstName">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                                {customer_id}
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 text-center lastName">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                                <Moment date={ordered_at} format="llll" />
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 text-center lastName">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                                &#8377; {total_sum}
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 text-center lastName">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                                {order_token}
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 emailAddress">
                                                            <div className='flex items-center justify-center'>
                                                                <p className={`border-dashed border-t border-gray-200 px-3 text-center py-1 rounded-md ` + statusColors[status_id]}>{payment_status}</p>
                                                            </div>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                            <div className='flex items-center space-x-5 justify-center'>
                                                                <div onClick={() => handleViewOrderPdf(order_id)}>
                                                                    <IconButton>
                                                                        <RemoveRedEyeIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                        <div className=" my-7">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={dataCount}
                                                previousLabel="<"
                                                renderOnZeroPageCount={null}
                                                containerClassName='flex items-center justify-center space-x-4'
                                                pageLinkClassName='pageNumber'
                                                previousLinkClassName='pageNumber'
                                                nextLinkClassName='pageNumber'
                                                activeLinkClassName='selectedPageNumber'
                                                disabledClassName='lastPage'
                                                disabledLinkClassName='lastPage'
                                            />
                                        </div>
                                    </>
                            }
                        </div>
                    }
                </div>
            </Layout>
        </>
    )
}

export default Orders