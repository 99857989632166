import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/dashboard";
import Category from "./pages/category";
import Products from "./pages/products";
import Services from "./pages/services";
import Orders from "./pages/orders";
import Customers from "./pages/customers";
import Coupons from "./pages/coupons";
// import SoldProducts from './pages/reports/SoldProducts';
// import ProductViewed from './pages/reports/ProductViewed';
import ProtectedRoutes from "./routes/ProtectedRoutes";
import Reports from "./pages/reports";
import { useEffect } from "react";
import { handleLogoutFromServer } from "./apis/logout";
import IdleTimer from "./helper/idleTimer";
import { useNavigate } from "react-router-dom";
import { IDLE_TIME_IN_SECONDS } from "./config";
import Blogs from "./pages/blogs";
import AddProduct from "./pages/products/AddProduct";
import Taxes from "./pages/taxes";
import "react-datepicker/dist/react-datepicker.css";
import Flavours from "./pages/variants/flavours";
import AppLanding from "./pages/app/AppLanding";
import DoNothingListing from "./pages/app/DoNothingListing";
import CaloriesListing from "./pages/app/CaloriesListing";
import MusicFormDoNothing from "./pages/app/MusicFormDoNothing";
import MoodCategoryListing from "./pages/app/MoodCategoryListing";
import MusicFormCalories from "./pages/app/AddFoodItem";
import EditDoNothing from "./pages/app/EditDoNothing";
import EditCalories from "./pages/app/EditFoodItem";
import CaloriesMoodCategory from "./pages/app/CaloriesMoodCategory";
import AddFoodItem from "./pages/app/AddFoodItem";
import EditFoodItem from "./pages/app/EditFoodItem";
import ExerciseListing from "./pages/app/exercise/ExcerciseListing";
import AddExercise from "./pages/app/exercise/AddExercise";
import MuscleListing from "./pages/app/muscle/MuscleListing";

const queryClient = new QueryClient();
function App() {
  // const handleLogout = () => {
  //   handleLogoutFromServer()
  //     .then((res) => {
  //       if (res.status === 200) {
  //         sessionStorage.clear();
  //         window.location.replace("/login")
  //         localStorage.removeItem('_expiredTime')
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     })
  // }

  // useEffect(() => {
  //   const timer = new IdleTimer({
  //     timeout: IDLE_TIME_IN_SECONDS,
  //     onTimeout: () => {
  //       handleLogout()
  //     },
  //     onExpired: () => {
  //       handleLogout()
  //     }
  //   });

  //   return () => {
  //     timer.cleanUp();
  //   };
  // }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/login" exact element={<Login />} />
          <Route
            path="/"
            exact
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/category"
            exact
            element={
              <ProtectedRoutes>
                <Category />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/products"
            exact
            element={
              <ProtectedRoutes>
                <Products />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/products/add-product"
            exact
            element={
              <ProtectedRoutes>
                <AddProduct />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/flavours"
            exact
            element={
              <ProtectedRoutes>
                <Flavours />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/services"
            exact
            element={
              <ProtectedRoutes>
                <Services />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/orders"
            exact
            element={
              <ProtectedRoutes>
                <Orders />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/customers"
            exact
            element={
              <ProtectedRoutes>
                <Customers />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/coupons"
            exact
            element={
              <ProtectedRoutes>
                <Coupons />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/reports"
            exact
            element={
              <ProtectedRoutes>
                <Reports />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app"
            exact
            element={
              <ProtectedRoutes>
                <AppLanding />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/blogs"
            exact
            element={
              <ProtectedRoutes>
                <Blogs />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/do-nothing"
            exact
            element={
              <ProtectedRoutes>
                <DoNothingListing />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/exercise"
            exact
            element={
              <ProtectedRoutes>
                <ExerciseListing/>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/do-nothing/edit-do-nothing/:musicId"
            exact
            element={
              <ProtectedRoutes>
                {/* <EditDoNothing/> */}
                <MusicFormDoNothing/>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/calories/edit-food-item/:foodItemId"
            exact
            element={
              <ProtectedRoutes>
                <AddFoodItem/>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/do-nothing/add-music"
            exact
            element={
              <ProtectedRoutes>
                <MusicFormDoNothing />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/do-nothing/mood-category"
            exact
            element={
              <ProtectedRoutes>
                <MoodCategoryListing />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/calories/mood-category"
            exact
            element={
              <ProtectedRoutes>
                <CaloriesMoodCategory />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/calories"
            exact
            element={
              <ProtectedRoutes>
                <CaloriesListing />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/calories/add-food-item"
            exact
            element={
              <ProtectedRoutes>
                <AddFoodItem/>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/exercise/add-exercise"
            exact
            element={
              <ProtectedRoutes>
                <AddExercise/>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/exercise/edit-exercise/:exerciseId"
            exact
            element={
              <ProtectedRoutes>
                <AddExercise/>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/app/exercise/muscle"
            exact
            element={
              <ProtectedRoutes>
                <MuscleListing/>
              </ProtectedRoutes>
            }
          />

          <Route
            path="/taxes"
            exact
            element={
              <ProtectedRoutes>
                <Taxes />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
