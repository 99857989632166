import { Modal } from '@mui/material'
import { useState } from 'react'
import useCreate from '../../../hooks/useCreate'
import toast from 'react-hot-toast';
import Loader from '../../loader';

const EditSlug = ({
    open,
    onClose,
    id,
    slug
}) => {
    const [confirmEdit, setConfirmEdit] = useState(false)
    const [newSlug, setNewSlug] = useState(slug || '')
    const { setDataToServer } = useCreate({
        url:'editSlugForProduct',
        refreshUrl:'productList',
        onSuccess: () => {
            handleCloseAll()
            toast.success('Slug Updated Successfully')
        },
        onError: (err) => {
            const {status,data} = err.response
            if(status === 400){
                alert(data?.message)
            }else{
                toast.error('Something went wrong please try again later')
            }
        }
    })
    const handlePostData = (e) => {
        e.preventDefault()
        let data = {
            slug: newSlug,
            id
        }
        setDataToServer.mutate(data)
    }
    const handleCloseAll = () => {
        setConfirmEdit(false)
        onClose()
        setNewSlug(newSlug)
    }
    return (
        <Modal open={open} onClose={handleCloseAll} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div className=' w-[40%] absolute top-4 left-1/2 -translate-x-1/2 bg-white px-5 py-4 rounded'>
                <div className=' space-y-5'>
                    {!confirmEdit && 
                    <div>
                        <h2 className=' font-semibold text-xl text-gray-600 text-center'>Are you sure you want to edit the slug</h2>
                        <p className=' font-semibold text-sm text-gray-500 text-center '>This will effect all the back links and SEO</p>
                    </div>}
                    <form onSubmit={handlePostData}>
                        {confirmEdit && 
                        <div className='flex-1 mb-5'>
                            <label htmlFor="Product Name" className='label'>New Slug <small className="text-red-700">*</small></label>
                            <input value={newSlug} required type="text" placeholder='New Slug' name='slug' className='input' onChange={e => setNewSlug(e.target.value)} />
                        </div>}
                        <div className=' text-center space-x-4'>
                            {confirmEdit
                                ? <button disabled={setDataToServer.isLoading} className=' font-semibold text-white bg-black rounded uppercase text-sm px-6 py-2'>
                                    <div className='flex items-center space-x-4'>
                                        <span>
                                            Save
                                        </span>
                                        {setDataToServer.isLoading &&  <Loader/>}
                                    </div>
                                </button>
                                :
                                <p onClick={() => setConfirmEdit(true)} className=' cursor-pointer inline-block font-semibold text-white bg-black rounded uppercase text-sm px-6 py-2'>
                                    Proceed
                                </p>
                            }
                            <button onClick={handleCloseAll} type='button' className=' font-semibold text-gray-700 border rounded uppercase text-sm px-6 py-2'>
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default EditSlug