// hooks/music/useEditMusic.js
import React from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
import useCreate from "../useCreate"; // Adjust the path as necessary
import { TOKEN_KEY } from "../../config";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../apis/axios";

const TOKEN = sessionStorage.getItem(TOKEN_KEY);

export const fetchSingleExercise = async (exerciseId) => {
  try {
    // Ensure that the URL includes the musicId for fetching specific music details
    const response = await axios.get(
      `exercise/fetch_single_exercise?exerciseId=${exerciseId}`,
      {
        headers: {
          Authorization: TOKEN ? `Bearer ${TOKEN}` : "",
        },
      }
    );

    // Return the data from the response
    return response.data;
  } catch (error) {
    // Handle errors appropriately
    toast.error("Error fetching exercise details!");
    console.error("Error fetching exercise details:", error);
    throw error;
  }
};

const useEditExercise = () => {
    const navigate = useNavigate();
  const { setDataToServer: updateExercise } = useCreate({
    refreshUrl: "exercise/exercise_listing",
    url: "exercise/update_exercise",
    onSuccess: () => {
      toast.success("Exercise updated successfully!");
    },
    onError: () => {
      toast.error("Error updating exercise!");
    },
  });

  const handleEditForm = (data, onSuccess, onError) => {
    updateExercise.mutate(data, {
      onSuccess: (response) => {
        toast.success("Exercise updated successfully!");
        if (onSuccess) onSuccess(response); // Call custom onSuccess if provided
        navigate(-1);
      },
      onError: (error) => {
        toast.error("Error updating exercise!");
        if (onError) onError(error); // Call custom onError if provided
      },
    });
  };

  return { fetchSingleExercise, updateExercise, handleEditForm };
};

export default useEditExercise;
