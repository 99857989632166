import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../apis/axios";
import { TOKEN_KEY } from "../config";

let TOKEN = sessionStorage.getItem(TOKEN_KEY);

function useUpload({ refreshUrl = "", onSuccess, onError }) {
  const queryClient = useQueryClient();

  const { mutate: uploadMedia } = useMutation(
    (formData) => uploadToServer(formData),
    {
      onError: (err) => {
        onError?.(err);
      },
      onSuccess: (data) => {
        if (refreshUrl) {
          queryClient.invalidateQueries(refreshUrl);
        }
        onSuccess?.(data);
      },
    }
  );

  const uploadToServer = async (formData) => {
    const response = await axios({
      method: "POST",
      url: "/upload/upload_media",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: TOKEN ? `Bearer ${TOKEN}` : "",
      },
    });
    return response.data;
  };

  const handleUpload = (file, screen) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('screen', screen);
    uploadMedia(formData);
  };

  return { handleUpload };
}

export default useUpload;
