import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config"; 
import Cookies from "universal-cookie";

// const cookies = new Cookies();
let token = sessionStorage.getItem(TOKEN_KEY);



export const getCouponList = async () => {
    // let token = cookies.get(TOKEN_KEY);
    let res = await axios({ method: "GET", url: `${API_ENDPOINT}couponlist`,headers: { Authorization: token ? `Bearer ${token}` : '', }})
    return res
};


export const addCoupon = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    for (var key in data) {
        if(Array.isArray(data[key])){
            fd.append(key, JSON.stringify(data[key]));
        }else{
            fd.append(key, data[key]);
        }
    }
    let res = await axios({ method: "POST", data:fd, url: `${API_ENDPOINT}couponcreate`,headers: { Authorization: token ? `Bearer ${token}` : '', }})
    return res
};

export const updateCoupon = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    for (var key in data) {
        fd.append(key, data[key]);
    }
    let res = await axios({ method: "POST", data:fd, url: `${API_ENDPOINT}couponupdate`,headers: { Authorization: token ? `Bearer ${token}` : '', }})
    return res
};


export const deleteCoupon = async (id) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    fd.append('id',id)
    let res = await axios({ method: "POST", data:fd, url: `${API_ENDPOINT}coupondelete`,headers: { Authorization: token ? `Bearer ${token}` : '', }})
    return res
};