import Layout from '../../components/layout'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Switch from '@mui/material/Switch';
import AddProduct from './AddProduct';
import React,{ useState } from 'react';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { getProductList, handleUpdateProductStatus } from '../../apis/product';
import Spinner from '../../components/spinner';
import Errors from '../errors';
import { Snackbar } from '@mui/material';
import MuiAlert  from '@mui/material/Alert';
import DeleteProduct from './DeleteProduct';
import EditProduct from './EditProduct';
import { useTableSearch } from '../../hooks/useTableSearch';
import { Link } from 'react-router-dom';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Products() {
    const queryClient = useQueryClient()
    const [openAddModal, setOpenAddModal] = useState(false)
    const [productId,setProductId] = useState(0)
    const [productVisibiliyId, setProductVisibiltyId] = useState(0)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const handleCloseModal = () => {
        setOpenAddModal(false)
        setOpenDeleteModal(false)
    }
    const handleOpenAddModal = () => {
        setOpenAddModal(true)
    }
    const handleModalClose = () => {
        setOpenAddModal(false)
        setIsOpenEditModal(false)
    }
    const handleOpenDeleteModal = (id) => {
        setOpenDeleteModal(true)
        setProductId(id)
    }
    const handleGetProductList = useQuery(['productList'], getProductList, {
        refetchOnWindowFocus: false
    })
    const [isDeletedSuccess,setIsDeletedSuccess] = useState(false)
    const deletedSuccess = () => {
        handleCloseModal()
        setIsDeletedSuccess(true)
    }
    const changeStatus = useMutation((data) => handleUpdateProductStatus(data), {
        onSuccess: (data) => {
            if (data.status === 200) {
                queryClient.invalidateQueries('productList')
                setProductVisibiltyId(0)
            }
        },
    })
    const handleChangeStatus = (e, id) => {
        setProductVisibiltyId(id)
        let status = e.target.checked
        let data = {
            id,
            home: status
        }
        changeStatus.mutate(data)
    }

    const [editData, setEditData] = useState({})
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const handleOpenEditModal = (id) => {
        setIsOpenEditModal(true)
        let data = handleGetProductList.data.data.data.find(x => x.id === id)
        setEditData(data)
    }

    const [searchVal, setSearcVal] = useState('');
    // const { filteredData } = useTableSearch({
    //     searchVal,
    //     retrieve: handleGetProductList?.data?.data
    // });
    const handleSearchVal = (e) => {
        let value = e.target.value
        setSearcVal(value)
    }
    return (
        <>
            <Layout>
                <div className='category'>
                    <div className='category__head headingBorder__b'>
                        <h2 className='titleHeading'>Products</h2>
                    </div>
                    <div className='category_table py-5 space-y-5'>
                        <div className='flex items-center space-x-4'>
                            <div className='flex-1'>
                                <input onChange={handleSearchVal} type="text" placeholder='Search Product....' className='input' />
                            </div>
                            <div>
                                <Link to={`add-product`} className='block'>
                                    <Button onClick={handleOpenAddModal} variant="outlined" startIcon={<AddIcon />} className='primaryBtn-outlined'>
                                        Add Product
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        {handleGetProductList.isLoading 
                        ? <Spinner />
                        : handleGetProductList.status === "error" 
                        ? <Errors errorObject={handleGetProductList.error} inline /> 
                        : <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Sr.no
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Product Image
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Product Name
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Product Type
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Product Code
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Show on Home
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {handleGetProductList.data.data?.data?.map((productData, idx) => {
                                                const { product_thumbnail, product_code, product_name, product_type, home, id,status } = productData
                                                return <tr key={idx}>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                            {idx + 1}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <div className=' w-20 h-20 mx-auto p-4'>
                                                            <img src={product_thumbnail} alt={`${product_name}`} className='w-full h-full object-contain' />
                                                        </div>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                            {product_name}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                            {product_type}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                            {product_code}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 emailAddress">
                                                        <div className='flex items-center justify-center'>
                                                            {id === productVisibiliyId ? <Spinner className={'w-8 h-8'} /> :
                                                            <>
                                                            <span className='text-gray-700 text-sm'>Hide</span>
                                                            <Switch inputProps={{ 'aria-label': 'controlled' }} onChange={(e) => handleChangeStatus(e, id)} className='hide_show_switch' checked={status} />
                                                            <span className='text-gray-700 text-sm'>Show</span></> }
                                                        </div>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                        <div className='flex items-center space-x-5 justify-center'>
                                                            <div>
                                                                <IconButton onClick={() => handleOpenEditModal(id)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </div>
                                                            <div  onClick={() => handleOpenDeleteModal(id)}>
                                                                <IconButton>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                        </div>}
                    </div>
                </div>
            </Layout>
            {isOpenEditModal && <EditProduct open={isOpenEditModal} handleClose={handleModalClose} editData={editData}/>}
            <DeleteProduct open={openDeleteModal} onClose={handleCloseModal} id={productId} onDelete={deletedSuccess}/>
            <Snackbar open={isDeletedSuccess} autoHideDuration={6000} onClose={() => setIsDeletedSuccess(false)}>
                <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                Category Deleted Succesfully
                </Alert>
            </Snackbar>
        </>
    )
}

export default Products